import { useState, useEffect } from "react";
import useAssociationClub from "./useAssociationClubData";
import { useUserStore } from "@store/useUserStore/useUserStore";
import { DataRange } from "@interfaces/FishingClubs";

export interface AssociationsNameUsersCount {
  [key: string]: number;
}

interface UseFishingDataReturn {
  usersCount: number;
  associationsNameUsersCount: AssociationsNameUsersCount;
}

const DEFAULT_NAME = "Brak okręgu PZW";

const useUsersSummary = (selectedRange: DataRange): UseFishingDataReturn => {
  const { associationClubs } = useAssociationClub();
  const { user, users, getUsers } = useUserStore();

  const [usersCount, setUsersCount] = useState(0);
  const [associationsNameUsersCount, setAssociationsUsersCount] = useState({});

  useEffect(() => {
    getUsers(user, selectedRange);
  }, [user, selectedRange]);

  useEffect(() => {
    const fetchFishingWithUser = async () => {
      const result = users.reduce<AssociationsNameUsersCount>((acc, user) => {
        acc[user.association_name || DEFAULT_NAME] =
          acc[user.association_name || DEFAULT_NAME] + 1 || 1;
        return acc;
      }, {});

      setUsersCount(users?.length);
      setAssociationsUsersCount(result);
    };

    fetchFishingWithUser().catch(console.error).finally();
  }, [associationClubs, users]);

  return { usersCount, associationsNameUsersCount };
};

export default useUsersSummary;

import { create } from "zustand";

import { RANGER_STORE_DEFAULT_STATE } from "./consts";
import { RangerStore } from "./interfaces";
import { fetchControls } from "@utils/api/fetchControls";

export const useRangerStore = create<RangerStore>((set) => {
  return {
    ...RANGER_STORE_DEFAULT_STATE,

    reset: () => set(RANGER_STORE_DEFAULT_STATE),
    getControls: async (userId) => {
      set({ loading: true });

      if (userId) {
        try {
          const controls = await fetchControls(userId);
          set({ controls, loading: false });
        } catch (e) {
          set({
            errorMessage: (e as Record<string, unknown>).message,
            loading: false,
          });
        }
      }
    },
  };
});

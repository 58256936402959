import { SyntheticEvent, useEffect } from "react";

import { Box, Button, Typography, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import Layout from "@components/Layout";

import { app } from "../firebase-config";
import { useCollectionsStore } from "@store/useCollectionsStore";

export const MaintenancePage = () => {
  const {
    loading,
    collections,
    getCollections,
    updateCollectionsAssociationByProduct,
  } = useCollectionsStore();
  const projectId = app.options.projectId ?? "---";

  useEffect(() => {
    getCollections();
  }, []);

  const handleUpdateAssociations = async (
    e: SyntheticEvent,
    collectionName: string,
  ) => {
    e.stopPropagation();
    updateCollectionsAssociationByProduct(collectionName);
  };

  const renderActionButtons = (params: GridRenderCellParams) => {
    const collectionName = params.row.name;

    let actions = <></>;
    switch (collectionName) {
      case "transactions":
        actions = (
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={(e) => handleUpdateAssociations(e, collectionName)}
          >
            Aktualizuj pole "Association"
          </Button>
        );
        break;
    }

    return actions;
  };

  const columns = [
    { field: "name", headerName: "Nazwa", width: 200, sortable: false },
    {
      field: "counter",
      headerName: "Ilość dokumentów",
      width: 150,
    },
    {
      field: "action",
      headerName: "Operacja",
      renderCell: (params: GridRenderCellParams) => renderActionButtons(params),
      flex: 1,
    },
  ];

  return (
    <Layout>
      <Grid container>
        <Grid xs={12} item>
          <Box component="section">
            <Box display="flex" sx={{ mb: 2 }}>
              <Typography variant="h6">Lista kolekcji</Typography>
              <Typography variant="h6" color="secondary" sx={{ ml: 1 }}>
                "{projectId}"
              </Typography>
            </Box>
            <DataGrid
              disableColumnFilter
              loading={loading}
              disableRowSelectionOnClick
              columns={columns}
              rows={collections}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import { tryCatchFunc } from "@utils/api/helpers";
import { Collection } from "@utils/api/enums";

import { AssociationClub } from "@interfaces/AssociationClub";

export const getAssociationClubs = async (
  finallyFunc: () => void = () => undefined,
): Promise<AssociationClub[]> => {
  const getFishing = async (): Promise<AssociationClub[]> => {
    const associationClubs = collection(db, Collection.ASSOCATION_CLUBS);
    const snapshot = await getDocs(associationClubs);
    return snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as unknown as AssociationClub[];
  };
  const catchFunc = (error: unknown): [] => {
    console.error("Failed to fetch association clubs data:", error);
    return [];
  };

  return tryCatchFunc(getFishing, catchFunc, finallyFunc);
};

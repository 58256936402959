import React, { useEffect, useState } from "react";
import { Fishing } from "@interfaces/Fishing";
import { DataRange } from "@interfaces/Date";
import { ChartData } from "@interfaces/Charts";
import { transformDataBarChartNumberCatches } from "@utils/charts";
import Loading from "@components/Loading";
import { CardComponent } from "@components/Card";
import { areElementsInArray } from "@utils/arrayUtils";
import { Box, Chip } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

interface BarChartNumberCatchesProps {
  loading: boolean;
  data: Fishing[];
  selectedRange: DataRange;
}

export const BarChartNumberCatches: React.FC<BarChartNumberCatchesProps> = ({
  loading,
  data,
  selectedRange,
}) => {
  const [barChartData, setBarChartData] = useState<ChartData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    if (!loading) {
      const transformedData: ChartData = transformDataBarChartNumberCatches(
        data,
        selectedRange,
      );
      setBarChartData(transformedData);
      setIsLoading(false);
    }
  }, [loading, data, selectedRange]);

  const valueFormatter = (value: number) => `Ilość połowów: ${value}`;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CardComponent title="Ilość połowów w danym dniu">
      {!barChartData ||
      !areElementsInArray(barChartData.xAxisData) ||
      !areElementsInArray(barChartData.seriesData) ? (
        <Box display="flex" sx={{ width: "100%", justifyContent: "center" }}>
          <Chip
            label="Brak danych w wybranym zakresie"
            color="info"
            variant="outlined"
          />
        </Box>
      ) : (
        <BarChart
          xAxis={[
            {
              data: barChartData.xAxisData,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: barChartData.seriesData,
              valueFormatter,
            },
          ]}
          height={300}
        />
      )}
    </CardComponent>
  );
};

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import {
  Auth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";

interface Props {
  isOpen: boolean;
  onClickClose: () => void;
  auth: Auth;
}

const ChangePasswordDialog = ({
  isOpen,
  onClickClose,
  auth: { currentUser },
}: Props) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [isError, setIsError] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handlePasswordChange = async () => {
    if (newPassword.length < 6) {
      setIsError("Hasło musi zawierać co najmniej 6 znaków");
      return;
    }

    if (currentPassword === newPassword) {
      setIsError("Hasła muszą się różnić");
      return;
    }
    if (newPassword === newPasswordRepeat) {
      if (currentUser?.email) {
        const credential = EmailAuthProvider.credential(
          currentUser.email,
          currentPassword,
        );

        try {
          await reauthenticateWithCredential(currentUser, credential);
          await updatePassword(currentUser, newPassword);

          setShowToast(true);
          setIsError("");
          onClickClose();
        } catch (isError) {
          if (isError) {
            setIsError("Niewłaściwe bieżące hasło");
          }
        }
      }
    } else {
      setIsError("Hasła muszą być identyczne");
    }
  };

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>Zmiana hasła</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            minWidth: 400,
          }}
        >
          {!!isError.length && <Alert severity="error">{isError}</Alert>}
          <TextField
            onChange={(e) => setCurrentPassword(e.target.value)}
            label="Stare hasło"
            variant="standard"
            fullWidth
            type="password"
          />
          <TextField
            onChange={(e) => setNewPassword(e.target.value)}
            label="Nowe hasło"
            variant="standard"
            fullWidth
            type="password"
          />
          <TextField
            onChange={(e) => setNewPasswordRepeat(e.target.value)}
            label="Powtórz hasło"
            variant="standard"
            fullWidth
            type="password"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClickClose();
              setIsError("");
            }}
          >
            Zamknij
          </Button>
          <Button variant="contained" onClick={handlePasswordChange}>
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showToast}
        onClose={() => setShowToast(false)}
        autoHideDuration={5000}
        message="Hasło zostało zmienione"
      />
    </>
  );
};

export default ChangePasswordDialog;

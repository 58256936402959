import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { ListMode } from "../enums";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FisheryMapMode } from "@store/useFisheriesStore/enums";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFisheriesStore } from "@store/useFisheriesStore";
import { useSnackbarStore } from "@store/useSnackbarStore/useSnackbarStore";
import { SnackbarVariant } from "@store/useSnackbarStore/enums";
import { useDialogStore } from "@store/useDialogStore/useDialogStore";
import { DEFAULT_FISHERY } from "@store/useFisheriesStore/consts";

type Props = {
  handleUpdateListView: (listMode: ListMode) => void;
};

const EditFishery = ({ handleUpdateListView }: Props) => {
  const {
    editFishery,
    mapMode,
    isFisheryEdited,
    handleDeleteCoordinateFromEditFishery,
    handleSetEditFishery,
    handleSaveEditFishery,
    handleSetMapMode,
    handleSetFisheryEdited,
  } = useFisheriesStore();

  const { handleOpenSnackbar } = useSnackbarStore();
  const { handleOpenDialog, handleSetButtonsDetails, handleResetDialogState } =
    useDialogStore();

  const goBack = () => {
    handleSetEditFishery(DEFAULT_FISHERY);
    handleUpdateListView(ListMode.LIST);
    handleSetMapMode(FisheryMapMode.STATIC);
    handleSetFisheryEdited(false);
    handleResetDialogState();
  };

  return (
    <Box height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ArrowBackIcon
          onClick={() => {
            if (isFisheryEdited) {
              handleOpenDialog({
                title: "Czy na pewno chcesz opuścić ten ekran?",
                content: (
                  <Typography variant="body1">
                    Twoje zmiany łowiska nie zostaną zapisane
                  </Typography>
                ),
                shouldCloseOnConfirm: true,
                closeFunc: goBack,
              });
              handleSetButtonsDetails({
                closeButtonTitle: "Opuść widok",
                confirmButtonTitle: "Zostań na tym widoku",
              });
              return;
            }
            goBack();
          }}
          style={{ minWidth: 0, cursor: "pointer" }}
        />
        <Typography variant="h6">{editFishery?.name}</Typography>
      </Box>

      <Box marginTop="16px" display="flex" justifyContent="space-between">
        {mapMode === FisheryMapMode.STATIC && (
          <Button
            onClick={() => handleSetMapMode(FisheryMapMode.EDIT_FISHERY)}
            color="secondary"
          >
            Edytuj koordynaty
          </Button>
        )}
        {mapMode === FisheryMapMode.EDIT_FISHERY && (
          <Button
            color="error"
            onClick={() => {
              handleSetMapMode(FisheryMapMode.STATIC);
            }}
          >
            Zakończ
          </Button>
        )}

        {mapMode === FisheryMapMode.STATIC && (
          <Button
            disabled={!isFisheryEdited}
            onClick={() =>
              editFishery &&
              handleSaveEditFishery(editFishery, () =>
                handleOpenSnackbar({
                  message: "Zapisano zmiany",
                  variant: SnackbarVariant.SUCCESS,
                }),
              )
            }
          >
            Zapisz
          </Button>
        )}
      </Box>
      <Box marginTop="16px" overflow="auto" maxHeight="calc(100% - 100px)">
        <Typography variant="h5">Koordynaty:</Typography>
        {editFishery?.location.map((location, index) => (
          <Box marginTop="8px" key={index}>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography fontWeight={700}>
                  Koordynat numer {index + 1}:
                </Typography>
              </Box>
              {mapMode === FisheryMapMode.EDIT_FISHERY && (
                <DeleteIcon
                  onClick={() => handleDeleteCoordinateFromEditFishery(index)}
                  color="error"
                  style={{ minWidth: 0, cursor: "pointer" }}
                />
              )}
            </Box>
            <Typography>szerokość: {location.lat}</Typography>
            <Typography>długość: {location.lng}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EditFishery;

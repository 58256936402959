import React from "react";
import { Typography } from "@mui/material";
import "./styles.scss";

interface LabelNumberItemProps {
  label: string;
  number: number;
}

export const LabelNumberItem: React.FC<LabelNumberItemProps> = ({
  label,
  number,
}) => {
  return (
    <div className="label-number-item">
      <div className="label-number-item__block">
        <Typography variant="caption" color="gray">
          Gatunek ryby
        </Typography>
        <Typography variant="caption" color="gray">
          Ilość
        </Typography>
      </div>
      <div className="label-number-item__block">
        <Typography variant="body2" fontWeight="bold">
          {label}
        </Typography>

        <Typography variant="body2" fontWeight="bold">
          {number}
        </Typography>
      </div>
    </div>
  );
};

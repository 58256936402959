import React from "react";
import Box from "@mui/material/Box";
import LogoutIcon from "@mui/icons-material/Logout";
import { auth } from "../../firebase-config";
import { signOut } from "firebase/auth";
import "./styles.scss";
import Typography from "@mui/material/Typography";
import { useUserStore } from "@store/useUserStore/useUserStore";
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { route } from "../../consts/routes";
import { resetAllStores } from "@store/index";

type HeaderProps = {
  width: string;
};
const Header: React.FC<HeaderProps> = ({ width }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    user: { email, photo_url },
  } = useUserStore();

  const handleLogout = async () => {
    resetAllStores();
    await signOut(auth);
  };

  return (
    <Box className="header" sx={{ width: width }}>
      <div className="header--wrapper">
        {email && (
          <>
            <Typography
              variant="body2"
              fontWeight="400"
              color="rgba(0,0,0,0.4)"
            >
              Witaj, {email}
            </Typography>
            <Tooltip title="Konto">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }} src={photo_url ?? ""} />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={() => navigate(route.PROFILE)}>
            <Avatar /> Profil użytkownika
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Wyloguj
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
};

export default Header;

import { useState, useEffect } from "react";
import { Association } from "@interfaces/Association";
import { getAssociations } from "@utils/getAssociationsData";

interface UseAssociationsDataReturn {
  associations: Association[];
  loading: boolean;
}

const useAssociationsData = (): UseAssociationsDataReturn => {
  const [associations, setAssociations] = useState<Association[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAssociations = async () => {
      const rawAssociations = await getAssociations();
      setAssociations(rawAssociations);
      setLoading(false);
    };

    fetchAssociations().catch(console.error);
  }, []);

  return { associations, loading };
};

export default useAssociationsData;

import React, { useState, useTransition, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { styled } from "@mui/material/styles";

import { Fishery } from "@interfaces/Fisheries";
import { DocumentReference } from "@firebase/firestore";
import { doc } from "firebase/firestore";
import { db } from "../../../firebase-config";

const StyledListItemButton = styled(ListItemButton)({
  paddingLeft: 0,
  paddingRight: 0,
  display: "flex",
  justifyContent: "space-between",
});

const StyledListItemText = styled(ListItemText)({
  paddingLeft: "8px",
  paddingRight: "8px",
});

const StyledListItemIcon = styled(ListItemIcon)({
  display: "flex",
  justifyContent: "center",
});

interface Props {
  fisheries: Fishery[];
  isOpen: boolean;
  onClickClose: () => void;
  onClickConfirm: (values: DocumentReference[]) => void;
  selectedFisheries: DocumentReference[] | null;
  selectedAssociation: DocumentReference | null;
}

const FisherySelectDialog = ({
  fisheries,
  isOpen,
  selectedFisheries,
  onClickClose,
  onClickConfirm,
  selectedAssociation,
}: Props) => {
  const [searchText, setSearchText] = useState("");
  const [selectedValues, setSelectedValues] = useState<DocumentReference[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, startTransition] = useTransition();
  useEffect(() => {
    if (selectedFisheries) {
      setSelectedValues(selectedFisheries);
    }
  }, [selectedFisheries?.length]);

  const handleOnSearchChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    startTransition(() => {
      setSearchText(e.target.value);
    });
  };

  const fisheriesIds = selectedValues?.map((fishery) => fishery.id);

  const searchResult = fisheries
    .filter(({ name }) => {
      return name && name.toLowerCase().includes(searchText.toLowerCase());
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleFisherySelect = (fishery: Fishery) => {
    setSelectedValues((prevState) => {
      const fisheryRef = doc(db, "fisheries", fishery.id as string);

      const index = prevState.findIndex((item) => item.id === fishery.id);

      if (index !== -1) {
        return prevState.filter((item) => item.id !== fishery.id);
      } else {
        return [...prevState, fisheryRef];
      }
    });
  };

  const favouriteFisheries = searchResult.filter((fishery) =>
    selectedFisheries?.find(
      (selectedFishery) => selectedFishery.id === fishery.id,
    ),
  );

  const associationFisheries = searchResult
    .filter((fishery) => fishery.association_id?.id === selectedAssociation?.id)
    .filter(
      (fishery) =>
        !favouriteFisheries.find(
          (favouriteFishery) => favouriteFishery.id === fishery.id,
        ),
    );

  const restFisheries = searchResult
    .filter(
      (fishery) =>
        !associationFisheries?.find(
          (associationFishery) => associationFishery.id === fishery.id,
        ),
    )
    .filter(
      (fishery) =>
        !favouriteFisheries.find(
          (favouriteFishery) => favouriteFishery.id === fishery.id,
        ),
    );

  const renderListItem = (fishery: Fishery) => (
    <StyledListItemButton
      key={fishery.id}
      onClick={() => handleFisherySelect(fishery)}
    >
      <StyledListItemText>{fishery.name}</StyledListItemText>
      <StyledListItemIcon>
        {fisheriesIds?.includes(fishery.id as string) ? (
          <FavoriteOutlinedIcon color="error" />
        ) : (
          <FavoriteBorderOutlinedIcon />
        )}
      </StyledListItemIcon>
    </StyledListItemButton>
  );

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>Wybierz łowisko</DialogTitle>
      <DialogContent>
        <TextField
          label="Wyszukaj"
          variant="standard"
          fullWidth
          onChange={handleOnSearchChange}
        />
        <List
          sx={{
            minHeight: "120px",
          }}
        >
          <ListSubheader>Ulubione łowiska</ListSubheader>
          {favouriteFisheries.map((fishery) => renderListItem(fishery))}

          {!!associationFisheries.length && (
            <ListSubheader>Łowiska z okręgu</ListSubheader>
          )}
          {associationFisheries.map((fishery) => renderListItem(fishery))}

          <ListSubheader>Pozostałe łowiska</ListSubheader>
          {restFisheries.map((fishery) => renderListItem(fishery))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSearchText("");
            onClickClose();
          }}
        >
          Zamknij
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setSearchText("");
            onClickConfirm(selectedValues);
          }}
        >
          Zatwierdź
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FisherySelectDialog;

import React from "react";
import Box from "@mui/material/Box";
import { FishingClubReportData } from "@interfaces/FishingClubs";
import { formatAmount, generateKeyWithPrice } from "../helpers";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { extractProductsWithEntryFee } from "@utils/fishingClubs/helpers";

export const extractEntryFeeColumns = (products: FishingClubReportData[]) => {
  const entryFeesMap: Map<string, number> = new Map();
  extractProductsWithEntryFee(products, entryFeesMap);

  return Array.from(entryFeesMap.entries()).map(
    ([key, price]): GridColDef => ({
      field: key,
      headerName: `Wpisowe (${formatAmount(price)} zł)`,
      width: 120,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.entryFeeCount?.[
          generateKeyWithPrice("entryFee", price)
        ];
      },
      renderHeader: () => (
        <Box
          sx={{ display: "flex", flexDirection: "column", lineHeight: "1.4" }}
        >
          <strong>Wpisowe</strong>
          <span>({formatAmount(price)} zł)</span>
        </Box>
      ),
    }),
  );
};

import React, { useEffect, useState } from "react";
import { Fishing } from "@interfaces/Fishing";
import { DataRange } from "@interfaces/Date";
import { FishCountBySpecies, PieChartData } from "@interfaces/Charts";
import { areElementsInArray } from "@utils/arrayUtils";
import Loading from "@components/Loading";
import { CardComponent } from "@components/Card";
import { Box, Grid, Chip } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { LabelNumberItem } from "@components/LabelNumberItem";
import { transformDataPieChartNumberFishCaught } from "@utils/charts";

interface PieChartNumberFishCaughtProps {
  loading: boolean;
  data: Fishing[];
  selectedRange: DataRange;
}

export const PieChartNumberFishCaught: React.FC<
  PieChartNumberFishCaughtProps
> = ({ loading, data, selectedRange }) => {
  const [pieChartData, setPieChartData] = useState<FishCountBySpecies[] | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    if (!loading) {
      const transformedData = transformDataPieChartNumberFishCaught(
        data,
        selectedRange,
      );
      setPieChartData(transformedData);
      setIsLoading(false);
    }
  }, [loading, data, selectedRange]);

  const chartData: PieChartData[] = (pieChartData || []).map(
    (item: FishCountBySpecies, i) => ({
      id: i,
      value: item.count,
      label: item.species,
    }),
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CardComponent title="Ilość złowionych ryb wg gatunku">
      {!areElementsInArray(chartData) ? (
        <Box display="flex" sx={{ width: "100%", justifyContent: "center" }}>
          <Chip
            label="Brak danych w wybranym zakresie"
            color="info"
            variant="outlined"
          />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <PieChart
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              series={[
                {
                  data: [...chartData],
                  highlightScope: {
                    faded: "global",
                    highlighted: "item",
                  },
                  faded: {
                    additionalRadius: -20,
                    color: "gray",
                  },
                },
              ]}
              slotProps={{ legend: { hidden: true } }}
              height={260}
            />
          </Grid>
          <Grid item xs={7}>
            <Box
              sx={{
                height: 260,
                overflowY: "auto",
                padding: "0 16px 0 10px",
              }}
            >
              {chartData.map((item, i) => (
                <LabelNumberItem
                  key={i}
                  label={item.label}
                  number={item.value}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
    </CardComponent>
  );
};

import React, { useState } from "react";

import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  GridToolbar,
  plPL,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import Layout from "@components/Layout";
import DatePickerDialog from "@components/DatePickerDialog";
import StyledDataGrid from "@components/StyledDataGrid";

import useFishingData from "@hooks/useFishingData";
import { parseTimestamp } from "@utils/helpers";
import { DataRange } from "@interfaces/FishingClubs";
import { Fish, Fishing } from "@interfaces/Fishing";

import { DEFAULT_START_END_DATE } from "../consts/date";

export const FishingPage: React.FC = () => {
  const [selectedRange, setSelectedRange] = useState<DataRange>(
    DEFAULT_START_END_DATE,
  );
  const filterFishingsByUsers = null;
  const { fishing, loading } = useFishingData(
    selectedRange,
    filterFishingsByUsers,
  );

  const rows: Fishing[] = fishing.map((item: Fishing, i: number): Fishing => {
    const fishesFilterText = (item.fishes || [])
      .map(
        (fish) =>
          `${fish.fish_name} ${fish.size}cm/${fish.weight}kg/${fish.count} - Wypuszczona: ${fish.released ? "Tak" : "Nie"}`,
      )
      .join(", ");

    return {
      id: i,
      association_card: item?.association_card,
      association_name: item?.association_name,
      association_club_name: item?.association_club_name,
      fishes_count: item?.fishes_count,
      fishery_name: item?.fishery_name,
      license_number: item?.license_number,
      fishing_card: item?.fishing_card,
      start_date: item.start_date,
      end_date: item.end_date,
      fishes_weight: item.fishes_weight,
      fishesFilterText,
      fishes: item?.fishes,
      isActive: item?.isActive,
    };
  });

  const ExpandableCell = ({ row }: GridRenderCellParams) => {
    const [showAll, setShowAll] = useState(false);

    const displayedFishes =
      row.fishes && Array.isArray(row.fishes)
        ? showAll
          ? row.fishes
          : row.fishes.slice(0, 1)
        : [];
    const hasMoreThanOne =
      row.fishes && Array.isArray(row.fishes) && row.fishes.length > 1;

    return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        {displayedFishes.map((item: Fish, i: number) => (
          <Box key={i}>
            <strong>{item.fish_name}</strong> {item.size}cm/{item.weight}
            kg/{item.count}szt Wypuszczona: {item.released ? "Tak" : "Nie"}
          </Box>
        ))}
        {hasMoreThanOne && (
          <Typography
            onClick={() => setShowAll(!showAll)}
            variant="caption"
            display="block"
            sx={{ cursor: "pointer", color: green[800] }}
          >
            {showAll ? "Pokaż mniej" : "Pokaż więcej"}
          </Typography>
        )}
      </Box>
    );
  };

  const handleConfirmDialogDateRange = (range: DataRange) => {
    setSelectedRange(range);
  };

  const columns: GridColDef[] = [
    {
      field: "start_date",
      headerName: "Start",
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        parseTimestamp(params.value, "yyyy-MM-dd HH:mm"),
      renderCell: (params) =>
        parseTimestamp(params.row.start_date, "dd/MM/yyyy HH:mm"),
    },
    {
      field: "association_name",
      headerName: "Okręg",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "association_card",
      headerName: "Nr legitymacji",
      flex: 1,
    },
    {
      field: "fishery_name",
      headerName: "Nazwa łowiska",
      flex: 1,
    },
    {
      field: "association_club_name",
      headerName: "Nazwa klubu",
      flex: 1,
    },
    {
      field: "fishing_card",
      headerName: "Nr karty",
      flex: 1,
    },
    {
      field: "license_number",
      headerName: "Nr licencji",
      flex: 1,
    },
    {
      field: "fishes_count",
      headerName: "Ilość złowionych ryb",
      flex: 1,
    },
    {
      field: "fishes_weight",
      headerName: "Waga złowionych ryb",
      flex: 1,
    },
    {
      field: "fishesFilterText",
      headerName: "Ryby",
      renderCell: (params) => {
        return <ExpandableCell {...params} />;
      },
      width: 200,
    },
  ];

  return (
    <Layout>
      <Grid container spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <DatePickerDialog onConfirm={handleConfirmDialogDateRange} />
        </Grid>
        <Grid item xs={12} sx={{ height: "calc(100% - 70px)" }}>
          <Box
            display="flex"
            flexDirection="row"
            sx={{ width: "100%", height: "100%", backgroundColor: "white" }}
          >
            <StyledDataGrid
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                  minHeight: 28,
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                  minHeight: 28,
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                  minHeight: 328,
                },
              }}
              getRowHeight={() => "auto"}
              disableRowSelectionOnClick
              rows={rows}
              loading={loading}
              columns={columns}
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
                columns: {
                  columnVisibilityModel: {
                    association_name: false,
                  },
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    utf8WithBom: true,
                  },
                },
              }}
              pageSizeOptions={[15, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

import Box from "@mui/material/Box";
import Popup from "./components/Popup";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { LatLng, LatLngExpression } from "leaflet";
import { getGoogleMapsUrl, getFirstLocation } from "@utils/geolocation";
import { Polygon, Polyline } from "react-leaflet";
import Marker from "./components/Marker";

import "leaflet/dist/leaflet.css";
import { Fishery } from "../../interfaces/Fishery";
import { areElementsInArray } from "@utils/arrayUtils";

export const getPopUpComponent = (location: LatLng | undefined, name: string) =>
  location && (
    <Popup
      text={
        <Box>
          <Typography>{name}</Typography>
          <Link
            target="_blank"
            href={getGoogleMapsUrl(location.lat, location.lng)}
            underline="none"
          >
            Koordynaty: {location.lat}, {location.lng}
          </Link>
        </Box>
      }
    />
  );
export const getPolygonComponent = (
  PopupComponent: React.ReactNode | null,
  positions: LatLngExpression[],
  color = "purple",
) => (
  <Polygon pathOptions={{ color }} positions={positions}>
    {PopupComponent}
  </Polygon>
);

export const getPolylineComponent = (
  PopupComponent: React.ReactNode | null,
  positions: LatLngExpression[],
  color = "blue",
) => (
  <Polyline pathOptions={{ color }} positions={positions}>
    {PopupComponent}
  </Polyline>
);

export const getMarkerComponent = (
  PopupComponent: React.ReactNode | null,
  location: LatLng | undefined,
) =>
  location ? (
    <Marker lat={location.lat} lng={location.lng}>
      {PopupComponent}
    </Marker>
  ) : null;

export const getLocationComponent = (
  fishery: Fishery | null,
  isPolylineMode: boolean,
) => {
  const location = fishery?.location || [];
  if (!fishery || !areElementsInArray(location)) return;

  const PopupComponent = getPopUpComponent(
    getFirstLocation(location),
    fishery.name,
  );

  return location.length === 1
    ? getMarkerComponent(
        PopupComponent,
        new LatLng(location[0].lat, location[0].lng),
      )
    : isPolylineMode
      ? getPolylineComponent(PopupComponent, location, "blue")
      : getPolygonComponent(PopupComponent, location, "purple");
};

import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { ListMode } from "../enums";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import { getLocationBounds } from "@utils/geolocation";
import { useUserStore } from "@store/useUserStore/useUserStore";
import { ROLES } from "@store/useUserStore/enums";
import { useFisheriesStore } from "@store/useFisheriesStore";
import { FisheryMapMode } from "@store/useFisheriesStore/enums";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Pagination, Typography } from "@mui/material";
import { FISHERIES_LIMIT_PAGE } from "@store/useFisheriesStore/consts";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { updateUserData, UserData } from "@utils/api/updateUserData";
import { useSnackbarStore } from "@store/useSnackbarStore/useSnackbarStore";
import { SnackbarVariant } from "@store/useSnackbarStore/enums";
import { getFisheryReference } from "@utils/api/getFisheryReference";

type Props = {
  handleUpdateListView: (listMode: ListMode) => void;
};

const FisheriesList = ({ handleUpdateListView }: Props) => {
  const {
    handleSetSelectedBoundsByUser,
    handleSetEditFishery,
    handleSetMapMode,
    handleSearchFisheries,
    totalCount,
    currentPage,
    handleGetNextFisheries,
    visibleFisheries,
    search,
  } = useFisheriesStore();

  const [searchName, setSearchName] = useState<string>(search.name);
  const [selectedId, setSelectedId] = useState<string>("");

  const { hasRole, user, setUser } = useUserStore();
  const canEdit = hasRole([ROLES.SUPERADMIN, ROLES.ADMIN]);

  const { handleOpenSnackbar } = useSnackbarStore();

  const toggleFavorite = async (id: string) => {
    const favoriteFisheries = user.favorite_fishery || [];
    let updatedFavoriteFisheries = [];

    const fisheryRef = await getFisheryReference(id);

    if (fisheryRef) {
      const index = favoriteFisheries.findIndex((item) => item.id === id);
      if (index !== -1) {
        updatedFavoriteFisheries = favoriteFisheries.filter(
          (item) => item.id !== id,
        );
      } else {
        updatedFavoriteFisheries = [...favoriteFisheries, fisheryRef];
      }

      if (user) {
        const payload: UserData = {
          fishing_card: user.fishing_card,
          association_card: user.association_card,
          association_club_name: user?.association_club_name,
          association_name: user.association_name,
          association_club_id: user.association_club_id,
          favorite_fishing_method: user.favorite_fishing_method,
          association_id: user.association_id,
          favorite_fishery: updatedFavoriteFisheries,
          display_name: user.display_name,
        };

        await updateUserData(user.uid, payload, () => {
          setUser(payload);
          handleOpenSnackbar({
            message: "Zapisano zmiany",
            variant: SnackbarVariant.SUCCESS,
          });
        });
      }
    }
  };

  return (
    <>
      {canEdit && (
        <Button
          onClick={() => {
            handleUpdateListView(ListMode.ADD_FISHERY);
            handleSetMapMode(FisheryMapMode.NEW_FISHERY);
          }}
        >
          Dodaj nowe Łowisko <AddIcon />
        </Button>
      )}
      <Box display="flex" gap="16px" marginBottom="8px">
        <TextField
          size="small"
          value={searchName}
          placeholder="Szukaj..."
          onChange={(e) => {
            setSearchName(e.target.value);
            handleSearchFisheries({ name: e.target.value });
          }}
        />
      </Box>
      <List style={{ overflow: "auto", maxHeight: `calc(100% - 150px)` }}>
        {visibleFisheries.map((item, index) => (
          <ListItem key={index}>
            <ListItemButton
              style={{
                borderBottom: "1px solid #ECEFF1",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor:
                  selectedId === item.id
                    ? "rgba(0, 0, 0, 0.08)"
                    : "rgba(0, 0, 0, 0)",
              }}
              onClick={() => {
                handleSetSelectedBoundsByUser(getLocationBounds(item.location));
                setSelectedId(item.id);
              }}
            >
              <ListItemText
                primary={`${(currentPage - 1) * FISHERIES_LIMIT_PAGE + (index + 1)}. ${item.name}`}
              />

              <ListItemIcon
                style={{ minWidth: 0, display: "flex", gap: "4px" }}
              >
                {canEdit && (
                  <EditIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSetEditFishery(item);
                      handleUpdateListView(ListMode.EDIT_FISHERY);
                      handleSetSelectedBoundsByUser(
                        getLocationBounds(item.location),
                      );
                    }}
                  />
                )}
                {!canEdit && (
                  <>
                    {user.favorite_fishery?.some(
                      (fishery) => fishery.id === item.id,
                    ) ? (
                      <FavoriteOutlinedIcon
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(item.id);
                        }}
                      />
                    ) : (
                      <FavoriteBorderOutlinedIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(item.id);
                        }}
                      />
                    )}
                  </>
                )}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
        {!visibleFisheries.length && (
          <Typography>Nie znaleziono łowisk.</Typography>
        )}
      </List>
      {visibleFisheries.length ? (
        <Box marginTop="16px" display="flex" justifyContent="center">
          <Pagination
            count={totalCount}
            siblingCount={0}
            page={currentPage}
            onChange={(_, page) =>
              handleGetNextFisheries(page, { name: searchName })
            }
          ></Pagination>
        </Box>
      ) : null}
    </>
  );
};
export default FisheriesList;

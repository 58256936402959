import { useCollectionsStore } from "./useCollectionsStore";
import { useDialogStore } from "./useDialogStore/useDialogStore";
import { useFisheriesStore } from "./useFisheriesStore";
import { useFishingStore } from "./useFishingStore";
import { useLicencesStore } from "./useLicencesStore";
import { useRangerStore } from "./useRangerStore";
import { useSnackbarStore } from "./useSnackbarStore/useSnackbarStore";
import { useTransactionsStore } from "./useTransactionsStore";
import { useUserStore } from "./useUserStore/useUserStore";

export const resetAllStores = () => {
  useCollectionsStore.getState().reset();
  useDialogStore.getState().reset();
  useFisheriesStore.getState().reset();
  useFishingStore.getState().reset();
  useLicencesStore.getState().reset();
  useRangerStore.getState().reset();
  useSnackbarStore.getState().reset();
  useTransactionsStore.getState().reset();
  useUserStore.getState().reset();
};

import { Fishing } from "@interfaces/Fishing";
import { endOfDay, startOfDay } from "date-fns";
import { DataRange } from "@interfaces/Date";

interface FishCountBySpecies {
  species: string;
  count: number;
}

export const transformDataPieChartNumberFishCaught = (
  data: Fishing[],
  selectedRange: DataRange,
): FishCountBySpecies[] => {
  const adjustedStartDate = startOfDay(selectedRange.startDate);
  const adjustedEndDate = endOfDay(selectedRange.endDate);

  const countsBySpecies: Record<string, number> = {};

  data.forEach((fishing: Fishing) => {
    fishing.fishes?.forEach((fish) => {
      if (fish.create_date?.seconds !== undefined) {
        const fishDate = new Date(fish.create_date.seconds * 1000);
        if (fishDate >= adjustedStartDate && fishDate <= adjustedEndDate) {
          const species = fish.fish_name;
          if (!countsBySpecies[species]) {
            countsBySpecies[species] = 0;
          }
          countsBySpecies[species] += fish.count ? fish.count : 0;
        }
      }
    });
  });

  return Object.entries(countsBySpecies).map(([species, count]) => ({
    species,
    count,
  }));
};

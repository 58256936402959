import React, { useEffect, useState } from "react";
import { DataRange } from "@interfaces/FishingClubs";
import Layout from "@components/Layout";
import DatePickerDialog from "@components/DatePickerDialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledDataGrid from "@components/StyledDataGrid";
import {
  GridToolbar,
  GridColDef,
  plPL,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { LICENCE_FEE } from "../consts/transaction";
import { formatAmount } from "@utils/helpers";
import { ColumnField } from "../consts/columnFields";
import { useTransactionsStore } from "@store/useTransactionsStore";
import { DEFAULT_START_END_DATE } from "../consts/date";
import { useUserStore } from "@store/useUserStore/useUserStore";

export const FishingClubsPage: React.FC = () => {
  const { user } = useUserStore();
  const { fishingClubs, getFishingClubs, loading } = useTransactionsStore();
  const [selectedRange, setSelectedRange] = useState<DataRange>(
    DEFAULT_START_END_DATE,
  );

  const handleConfirmDialogDateRange = (range: DataRange) => {
    setSelectedRange(range);
  };

  useEffect(() => {
    getFishingClubs(user, selectedRange);
  }, [user, selectedRange]);

  const columns: GridColDef[] = [
    {
      field: ColumnField.CLUB_ID,
      headerName: "ID Koła",
      width: 80,
    },
    {
      field: ColumnField.CLUB_NAME,
      headerName: "Koło wędkarskie",
      minWidth: 260,
    },
    {
      field: ColumnField.LICENCE_COUNT,
      headerName: `Składka członkowska (${formatAmount(LICENCE_FEE)}zł)`,
      width: 220,
      renderHeader: () => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "1.4",
          }}
        >
          <strong>Składka członkowska</strong>
          <span>({formatAmount(LICENCE_FEE)}zł)</span>
        </Box>
      ),
    },
    ...fishingClubs.licenseWithDiscountColumns,
    ...fishingClubs.entryFeeColumns,
    ...fishingClubs.licenseColumns,
    {
      field: ColumnField.TOTAL_PRICE,
      width: 180,
      headerName: "Wartość rozliczenia",
      valueGetter: (params: GridValueGetterParams) => {
        return formatAmount(params.value);
      },
      renderCell: (params) => (
        <strong>{formatAmount(params.row.totalPrice)} zł</strong>
      ),
    },
  ];

  return (
    <Layout>
      <Grid container spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <DatePickerDialog onConfirm={handleConfirmDialogDateRange} />
        </Grid>
        <Grid item xs={12} sx={{ height: "calc(100% - 70px)" }}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ width: "100%", height: "100%", backgroundColor: "white" }}
          >
            <StyledDataGrid
              disableRowSelectionOnClick
              rows={fishingClubs.groupedProducts}
              columnHeaderHeight={110}
              columns={columns}
              loading={loading}
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
                sorting: {
                  sortModel: [{ field: ColumnField.CLUB_ID, sort: "asc" }],
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    utf8WithBom: true,
                  },
                },
              }}
              pageSizeOptions={[15, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

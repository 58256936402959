export function getFishingMethodName(method: number): string | null {
  if (method == null) return null;

  switch (method) {
    case 1:
      return "Gruntowa";
    case 2:
      return "Spławikowa";
    case 3:
      return "Spinningowa";
    case 4:
      return "Muchowa";
    case 5:
      return "Podlodowa";
    default:
      return null;
  }
}

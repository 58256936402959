import { useState, useEffect } from "react";
import { FishingWithUser } from "../interfaces/Fishing";
import useFishingData from "./useFishingData";
import { getAssociationUser } from "@utils/getAssociationUser";
import { useUserStore } from "@store/useUserStore/useUserStore";

interface UseFishingDataReturn {
  fishingsWithUser: FishingWithUser[];
  loading: boolean;
}

const useFishingDataWithUser = (): UseFishingDataReturn => {
  const { users } = useUserStore();
  const selectedRange = null;
  const { fishing, loading } = useFishingData(selectedRange, users);

  const [fishingsWithUser, setFishingsWithUser] = useState<FishingWithUser[]>(
    [],
  );
  const [fishingWithUserLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFishingWithUser = async () => {
      const fishingsListWithUsersPromise = await fishing.map(
        async (fishing) => {
          const user = await getAssociationUser(fishing.user);
          return {
            ...fishing,
            user,
          } as FishingWithUser;
        },
      );

      const fishingsListWithUsers = await Promise.all(
        fishingsListWithUsersPromise,
      );
      setFishingsWithUser(fishingsListWithUsers);
    };

    fetchFishingWithUser()
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [loading, fishing]);

  return { fishingsWithUser, loading: fishingWithUserLoading };
};

export default useFishingDataWithUser;

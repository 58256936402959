import { Transaction } from "@interfaces/Transactions";
import { getAssociationClub } from "./getAssociationClubData";
import { GridColDef } from "@mui/x-data-grid";
import {
  FishingClubReportData,
  AggregatedProduct,
} from "@interfaces/FishingClubs";
import {
  extractEntryFeeColumns,
  extractLicenseColumns,
  extractLicenseWithDiscountColumns,
  groupAndAggregateProducts,
} from "./fishingClubs";

export const transformFishingClubsData = async (
  data: Transaction[],
): Promise<{
  entryFeeColumns: GridColDef[];
  groupedProducts: AggregatedProduct[];
  licenseColumns: GridColDef[];
  licenseWithDiscountColumns: GridColDef[];
}> => {
  const productsPromises = data.flatMap(({ id, products }) =>
    products.map(async (product) => {
      const associationClub = await getAssociationClub(
        product.associationClubId,
      );
      return associationClub
        ? {
            ...product,
            transID: id,
            clubId: associationClub.club_id,
            clubName: associationClub.name,
          }
        : null;
    }),
  );

  const filteredProducts = (await Promise.all(productsPromises)).filter(
    Boolean,
  ) as FishingClubReportData[];

  const groupedProducts = groupAndAggregateProducts(filteredProducts);
  const licenseWithDiscountColumns =
    extractLicenseWithDiscountColumns(filteredProducts);
  const licenseColumns = extractLicenseColumns(filteredProducts);
  const entryFeeColumns = extractEntryFeeColumns(filteredProducts);

  return {
    groupedProducts,
    licenseColumns,
    entryFeeColumns,
    licenseWithDiscountColumns,
  };
};

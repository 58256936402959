import { create } from "zustand";
import { FisheriesState, SearchFisheryPayload } from "./interfaces";
import {
  DEFAULT_FISHERIES_STATE,
  DEFAULT_FISHERY,
  FISHERIES_LIMIT_PAGE,
} from "./consts";
import { LatLng } from "leaflet";
import { FisheryMapMode } from "./enums";
import { Fishery } from "@interfaces/Fishery";
import { editFisheryData } from "@utils/api/editFishery";
import { ResponseStatus } from "@utils/api/enums";
import { getFisheriesData } from "@utils/api/getFisheriesData";
import { addFisheryData } from "@utils/api/addFishery";
import { User } from "@interfaces/User";
import { ROLES } from "@store/useUserStore/enums";
import { getFisheriesByAssociation } from "@utils/api/getFisheriesByAssociation";

const filterFisheries = (fisheries: Fishery[] = [], searchName = "") =>
  fisheries.filter((fishery) => {
    const lowerCaseName = fishery?.name?.toLowerCase() || "";
    return lowerCaseName.includes(searchName.toLowerCase());
  });

const sortUserFisheries = (fisheries: Fishery[] = [], user: User) => {
  const fisheriesIds =
    user.favorite_fishery?.map((fishery) => fishery.id) || [];

  return fisheries.sort((a, b) => {
    const isFirstFavoriteFishery = fisheriesIds.includes(a.id);
    const isFirstAssociation = user.association_id?.id === a.association_id?.id;
    const isSecondFavoriteFishery = fisheriesIds.includes(b.id);
    const isSecondAssociation =
      user.association_id?.id === b.association_id?.id;
    if (isFirstFavoriteFishery) {
      return -1;
    }
    if (isSecondFavoriteFishery) {
      return 1;
    }
    if (isFirstAssociation) {
      return -1;
    }
    if (isSecondAssociation) {
      return 1;
    }
    return 0;
  });
};

export const useFisheriesStore = create<FisheriesState>((set) => ({
  ...DEFAULT_FISHERIES_STATE,

  reset: () => set(DEFAULT_FISHERIES_STATE),
  handleSetFisheries: (fisheries: Fishery[]) => set(() => ({ fisheries })),
  handleGetFisheries: async (user: User) => {
    const isSuperAdmin = user.roles.some((role) =>
      [ROLES.SUPERADMIN].includes(role),
    );
    const isAdmin = user.roles.some((role) => [ROLES.ADMIN].includes(role));
    const rawFisheries = isAdmin
      ? await getFisheriesByAssociation(user.association_id)
      : await getFisheriesData();
    const fisheries =
      isAdmin || isSuperAdmin
        ? rawFisheries
        : sortUserFisheries(rawFisheries, user);

    if (fisheries && fisheries.length) {
      set({
        fisheries,
        visibleFisheries: fisheries.slice(0, FISHERIES_LIMIT_PAGE),
        totalCount: Math.ceil(fisheries.length / FISHERIES_LIMIT_PAGE),
      });
    }
  },
  handleSetSelectedBoundsByUser: (selectedBoundsByUser) =>
    set(() => ({ selectedBoundsByUser })),
  handleSetMapMode: (mapMode: FisheryMapMode) => set(() => ({ mapMode })),
  handleSetNewFisheryPoint: (newPoint: LatLng) =>
    set((state: FisheriesState) => {
      const newFishery = {
        ...state.newFishery,
        location: [
          ...state.newFishery.location,
          { lat: newPoint.lat, lng: newPoint.lng },
        ],
      };
      return { newFishery, isFisheryEdited: true };
    }),

  handleSetNewFisheryName: (name: string) =>
    set((state: FisheriesState) => {
      const newFishery = { ...state.newFishery, name };
      return { newFishery, isFisheryEdited: true };
    }),

  handleResetNewFishery: () => set(() => ({ newFishery: DEFAULT_FISHERY })),
  handleDeleteCoordinateFromNewFishery: (index: number) =>
    set((state: FisheriesState) => {
      const newFishery = {
        ...state.newFishery,
        location: state.newFishery.location.filter(
          (_, loopedIndex) => index !== loopedIndex,
        ),
      };
      return { newFishery, isFisheryEdited: true };
    }),
  handleSetEditFishery: (editFishery: Fishery) => set(() => ({ editFishery })),
  handleSetNewEditFisheryPoint: (newPoint: LatLng) =>
    set((state: FisheriesState) => {
      const editFishery = state.editFishery
        ? {
            ...state.editFishery,
            location: [
              ...state.editFishery.location,
              { lat: newPoint.lat, lng: newPoint.lng },
            ],
          }
        : DEFAULT_FISHERY;
      return { editFishery, isFisheryEdited: true };
    }),
  handleDeleteCoordinateFromEditFishery: (index: number) =>
    set((state: FisheriesState) => {
      const editFishery = state.editFishery
        ? {
            ...state.editFishery,
            location: state.editFishery.location.filter(
              (_, loopedIndex) => index !== loopedIndex,
            ),
          }
        : DEFAULT_FISHERY;
      return { editFishery, isFisheryEdited: true };
    }),
  handleSaveNewFishery: async (newFishery, cb) => {
    const { status, doc } = await addFisheryData(newFishery);
    if (status === ResponseStatus.SUCCESS && doc) {
      set((state: FisheriesState) => {
        const newFisheryElement = { ...newFishery, id: doc.id };
        const fisheries = [...state.fisheries, newFisheryElement];
        cb();
        return {
          fisheries,
          isFisheryEdited: false,
          newFishery: DEFAULT_FISHERY,
          editFishery: newFisheryElement,
          mapMode: FisheryMapMode.STATIC,
        };
      });
    }
  },
  handleSaveEditFishery: async (editFishery: Fishery, cb: () => void) => {
    const status = await editFisheryData(editFishery);
    if (status === ResponseStatus.SUCCESS) {
      set((state: FisheriesState) => {
        const visibleFisheries = state.visibleFisheries.map(
          (visibleFishery) => {
            if (visibleFishery.id === editFishery.id) {
              return { ...editFishery };
            }
            return visibleFishery;
          },
        );
        const fisheries = state.fisheries.map((fishery) => {
          if (fishery.id === editFishery.id) return editFishery;
          return fishery;
        });
        cb();
        return { fisheries, isFisheryEdited: false, visibleFisheries };
      });
    }
  },
  handleSetFisheryEdited: (isFisheryEdited) => set(() => ({ isFisheryEdited })),
  handleSearchFisheries: async (search: SearchFisheryPayload) => {
    const { fisheries } = useFisheriesStore.getState();
    const updatedFisheries = filterFisheries(fisheries, search.name);

    set(() => ({
      search,
      currentPage: 1,
      totalCount: Math.ceil(updatedFisheries.length / FISHERIES_LIMIT_PAGE),
      visibleFisheries: updatedFisheries.slice(0, FISHERIES_LIMIT_PAGE),
    }));
  },
  handleGetNextFisheries: (
    currentPage: number,
    search: SearchFisheryPayload,
  ) => {
    const { fisheries } = useFisheriesStore.getState();
    const updatedFisheries = filterFisheries(fisheries, search.name);
    set(() => ({
      currentPage,
      visibleFisheries: updatedFisheries.slice(
        (currentPage - 1) * FISHERIES_LIMIT_PAGE,
        currentPage * FISHERIES_LIMIT_PAGE,
      ),
    }));
  },
}));

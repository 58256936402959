import { FisheryMapMode } from "./enums";

export const DEFAULT_CENTER = {
  lat: 52.1347,
  lng: 21.0042,
};
const DEFAULT_ZOOM = 13;
export const FISHERIES_LIMIT_PAGE = 20;
export const DEFAULT_FISHERY = {
  name: "",
  id: "",
  location: [],
  association_id: null,
};

export const DEFAULT_FISHERIES_STATE = {
  fisheries: [],
  visibleFisheries: [],
  selectedBoundsByUser: undefined,
  zoom: DEFAULT_ZOOM,
  mapMode: FisheryMapMode.STATIC,
  newFishery: DEFAULT_FISHERY,
  editFishery: DEFAULT_FISHERY,
  isFisheryEdited: false,
  totalCount: 0,
  currentPage: 1,
  search: { name: "" },
};

import React, { useEffect, useRef, useState } from "react";
import FisheriesMap from "@components/FisheriesMap";
import Grid from "@mui/material/Grid";
import Layout from "@components/Layout";
import { ListMode } from "@components/FisheriesList/enums";
import {
  AddFishery,
  EditFishery,
  FisheriesList,
} from "@components/FisheriesList";
import { useFisheriesStore } from "@store/useFisheriesStore";
import { useUserStore } from "@store/useUserStore/useUserStore";

export const FisheriesPage: React.FC = () => {
  const initialized = useRef(false);
  const { editFishery, handleGetFisheries, handleSetFisheryEdited } =
    useFisheriesStore();
  const [listMode, setListMode] = useState<ListMode>(ListMode.LIST);
  const { user } = useUserStore();

  const handleUpdateListView = (newListMode: ListMode) => {
    setListMode(newListMode);
  };

  useEffect(() => {
    if (!initialized.current && user.uid) {
      initialized.current = true;
      handleGetFisheries(user);
    }
    return () => {
      handleSetFisheryEdited(false);
    };
  }, [user]);

  return (
    <Layout>
      <Grid container style={{ maxHeight: "100%" }}>
        <Grid item xs={3} style={{ maxHeight: "100%", paddingRight: "16px" }}>
          {listMode === ListMode.LIST && (
            <FisheriesList handleUpdateListView={handleUpdateListView} />
          )}
          {listMode === ListMode.EDIT_FISHERY && editFishery && (
            <EditFishery handleUpdateListView={handleUpdateListView} />
          )}
          {listMode === ListMode.ADD_FISHERY && (
            <AddFishery handleUpdateListView={handleUpdateListView} />
          )}
        </Grid>
        <Grid item xs={9}>
          <FisheriesMap />
        </Grid>
      </Grid>
    </Layout>
  );
};

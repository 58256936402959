import React, { useEffect, useState } from "react";
import Layout from "@components/Layout";
import { Grid, Box } from "@mui/material";
import DatePickerDialog from "@components/DatePickerDialog";
import { DEFAULT_START_END_DATE } from "../../consts/date";
import { DataRange } from "@interfaces/Date";
import { useFishingStore } from "@store/useFishingStore";
import { useUserStore } from "@store/useUserStore/useUserStore";
import {
  PieChartNumberFishCaught,
  BarChartNumberFishCaught,
  BarChartNumberCatches,
  BarChartDivisionFish,
} from "@pages/Dashboard/charts";

export const Dashboard: React.FC = () => {
  const { user } = useUserStore();
  const { loading, fishing, getFishing } = useFishingStore();

  const [selectedRange, setSelectedRange] = useState<DataRange>(
    DEFAULT_START_END_DATE,
  );
  const handleConfirm = (range: { startDate: Date; endDate: Date }) =>
    setSelectedRange(range);

  useEffect(() => {
    getFishing(user, null, selectedRange);
  }, [user, selectedRange]);

  return (
    <Layout>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DatePickerDialog onConfirm={handleConfirm} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <PieChartNumberFishCaught
              loading={loading}
              data={fishing}
              selectedRange={selectedRange}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <BarChartNumberFishCaught
              loading={loading}
              data={fishing}
              selectedRange={selectedRange}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <BarChartNumberCatches
              loading={loading}
              data={fishing}
              selectedRange={selectedRange}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <BarChartDivisionFish
              loading={loading}
              data={fishing}
              selectedRange={selectedRange}
            />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

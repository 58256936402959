export enum Collection {
  ASSOCATIONS = "associations",
  ASSOCATION_CLUBS = "association_clubs",
  FISHERIES = "fisheries",
  FISHINGS = "fishings",
  LICENCES = "licences",
  USERS = "users",
  TRANSACTIONS = "transactions",
  SSR = "ssr_controls",
}

export enum DefaultParams {
  LIMIT = 10,
}

export enum ResponseStatus {
  SUCCESS = 200,
  FAIL = 500,
}

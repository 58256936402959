import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Dashboard,
  LoginPage,
  FishingPage,
  LicencesPage,
  TransactionsPage,
  FisheriesPage,
  FishingClubsPage,
  UsersSummaryPage,
  UsersPage,
  PeriodicPermitsPage,
  ProfilePage,
  MaintenancePage,
  NotFoundPage,
  RangerReportPage,
} from "./pages";
import ProtectedRoute from "@utils/protectedRoute";
import { route } from "./consts/routes";
import { useAuth } from "@contexts/AuthContext";
import Loading from "@components/Loading";

function App() {
  const { loading } = useAuth();

  if (loading) return <Loading />;

  return (
    <Router>
      <Routes>
        <Route path={route.LOGIN} element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path={route.HOME} element={<Dashboard />} />
          <Route path={route.FISHING} element={<FishingPage />} />
          <Route path={route.LICENCES} element={<LicencesPage />} />
          <Route path={route.TRANSACTIONS} element={<TransactionsPage />} />
          <Route path={route.FISHERIES} element={<FisheriesPage />} />
          <Route path={route.FISHING_CLUBS} element={<FishingClubsPage />} />
          <Route path={route.USERS_SUMMARY} element={<UsersSummaryPage />} />
          <Route path={route.USERS} element={<UsersPage />} />
          <Route path={route.RANGER_REPORT} element={<RangerReportPage />} />
          <Route
            path={route.PERIODIC_PERMITS}
            element={<PeriodicPermitsPage />}
          />
          <Route path={route.PROFILE} element={<ProfilePage />} />
          <Route path={route.MAINTENANCE} element={<MaintenancePage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";
import { doc, deleteDoc } from "firebase/firestore";
import {
  getAuth,
  updateProfile,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase-config";
import {
  Avatar,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  Input,
  Alert,
  Snackbar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

import Layout from "@components/Layout";
import FisherySelectDialog from "@components/Modals/FisherySelectDialog";
import AssociationEditDialog, {
  AssociationDialogPayload,
} from "@components/Modals/AssociationEditDialog";
import ChangePasswordDialog from "@components/Modals/ChangePasswordDialog";
import useAssociationsData from "@hooks/useAssociationsData";
import useAssociationClubData from "@hooks/useAssociationClubData";
import useFisheriesData from "@hooks/useFisheriesData";
import { updateUserData, UserData } from "@utils/api/updateUserData";
import { useUserStore } from "@store/useUserStore/useUserStore";
import { DocumentReference } from "@firebase/firestore";

const FAVOURITE_METHODS = [
  {
    label: "Gruntowa",
    value: 1,
  },
  {
    label: "Spławikowa",
    value: 2,
  },
  {
    label: "Spinningowa",
    value: 3,
  },
  {
    label: "Muchowa",
    value: 4,
  },
  {
    label: "Podłowkowa",
    value: 5,
  },
];

const getFisheriesPluralFormating = (length: number) => {
  if (length === 1) {
    return "ulubione łowisko";
  } else if (length >= 2 && length <= 4) {
    return "ulubione łowiska";
  } else if (length > 4) {
    return "ulubionych łowisk";
  } else {
    return "";
  }
};

export const ProfilePage = () => {
  const { user: currentUser } = useUserStore();
  const auth = getAuth();
  const [userData, setUserData] = useState(currentUser);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isEditNameOpen, setIsEditNameOpen] = useState(false);
  const [isEditEmailOpen, setIsEditEmailOpen] = useState(false);
  const [isChangeImageOpen, setIsChangeImageOpen] = useState(false);
  const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState(false);
  const [isFisherySelectOpen, setIsSpotSelectOpen] = useState(false);
  const [isAssociationEditOpen, setIsAssociationEditOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [emailChangeError, setEmailChangeError] = useState("");
  const [deleteAccountError, setDeleteAccountError] = useState("");

  useEffect(() => {
    setUserData(currentUser);
  }, [currentUser]);

  const { fisheries } = useFisheriesData();
  const { associationClubs } = useAssociationClubData();
  const { associations } = useAssociationsData();

  const handleClickSave = async () => {
    if (currentUser) {
      const payload: UserData = {
        fishing_card: userData.fishing_card,
        association_card: userData.association_card,
        association_club_name: userData?.association_club_name,
        association_name: userData.association_name,
        association_club_id: userData.association_club_id,
        favorite_fishing_method: userData.favorite_fishing_method,
        association_id: userData.association_id,
        favorite_fishery: userData.favorite_fishery,
        display_name: userData.display_name,
      };

      await updateUserData(currentUser.uid, payload, () => {
        setToastMessage("Zmiany zostały zapisane");
        setShowToast(true);
      });
    }
  };

  const uploadPhoto = async (file: File) => {
    try {
      const storageRef = ref(
        storage,
        `users/${userData.uid}/uploads/${file.name}`,
      );

      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    } catch (error) {
      console.error("Error uploading photo:", error);
      throw error;
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    if (file) {
      try {
        const compressedFile = await imageCompression(file, options);
        const downloadURL = await uploadPhoto(compressedFile);

        if (auth.currentUser) {
          await updateProfile(auth.currentUser, { photoURL: downloadURL });
          await updateUserData(
            currentUser.uid,
            { photo_url: downloadURL },
            () => {
              setUserData({
                ...userData,
                photo_url: downloadURL,
              });
            },
          );
        }
      } catch (error) {
        console.error("Error uploading photo:", error);
      }
    }
  };

  const handleEmailChange = async () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

    if (currentPassword.length === 0) {
      setEmailChangeError("Podaj hasło");
      return;
    }
    if (newEmail.length === 0) {
      setEmailChangeError("Podaj email");
      return;
    }
    if (!emailRegex.test(newEmail)) {
      setEmailChangeError("Nieprawidłowy email");
      return;
    } else {
      if (auth.currentUser?.email) {
        try {
          const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            currentPassword,
          );
          await reauthenticateWithCredential(auth.currentUser, credential);
          await updateEmail(auth.currentUser, newEmail);
          await updateUserData(
            currentUser.uid,
            {
              email: newEmail,
            },
            () => {
              setNewEmail("");
              setCurrentPassword("");
              setIsEditEmailOpen(false);
              setToastMessage("Email został zmieniony");
              setShowToast(true);
              setUserData({
                ...userData,
                email: newEmail,
              });
            },
          );
        } catch (error) {
          if (
            (error as Record<string, unknown>).code === "auth/wrong-password"
          ) {
            setEmailChangeError("Niepoprawne hasło");
          } else {
            setEmailChangeError("Coś poszło nie tak, spróbuj ponownie");
          }
        }
      }
    }
  };

  const handleConfirmDeleteAccount = async () => {
    if (currentPassword.length === 0) {
      setDeleteAccountError("Podaj hasło");
      return;
    }
    if (auth.currentUser?.email) {
      try {
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          currentPassword,
        );
        await reauthenticateWithCredential(auth.currentUser, credential);
        await deleteDoc(doc(db, "users", auth.currentUser.uid));
        await auth.currentUser?.delete();
      } catch (error) {
        if ((error as Record<string, unknown>).code === "auth/wrong-password") {
          setDeleteAccountError("Niepoprawne hasło");
        } else {
          setDeleteAccountError("Coś poszło nie tak, spróbuj ponownie");
        }
      }
    }
  };

  const handleSaveAssociationDialog = (payload: AssociationDialogPayload) => {
    setUserData({
      ...userData,
      ...payload,
    });
  };

  return (
    <Layout>
      <Grid container>
        <Grid xs={12} item>
          <Grid xs={12} item height="120px">
            <Box component="section" display="flex" alignItems="center">
              <Avatar src={userData.photo_url ?? ""} />

              <Box ml={2}>
                {isEditNameOpen ? (
                  <OutlinedInput
                    size="small"
                    value={userData?.display_name}
                    endAdornment={
                      <InputAdornment position="end">
                        <CheckIcon
                          onClick={() => setIsEditNameOpen(false)}
                          sx={{
                            cursor: "pointer",
                          }}
                        />
                      </InputAdornment>
                    }
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >,
                    ) =>
                      setUserData({ ...userData, display_name: e.target.value })
                    }
                  />
                ) : (
                  <Typography variant="h6" alignItems="center" display="flex">
                    {userData?.display_name || "Imię i Nazwisko"}
                    <EditIcon
                      onClick={() => setIsEditNameOpen(true)}
                      sx={{
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    />
                  </Typography>
                )}
                <Typography marginTop={1}>
                  {userData?.email}
                  {!isEditEmailOpen && (
                    <EditIcon
                      onClick={() => setIsEditEmailOpen(true)}
                      sx={{
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Typography>
              </Box>
              <Box ml={2}>
                {isChangeImageOpen && (
                  <Input
                    type="file"
                    inputProps={{
                      accept: "image/*",
                    }}
                    ref={fileInputRef}
                    onChange={handleImageChange}
                  />
                )}

                <Button
                  onClick={() => setIsChangeImageOpen(!isChangeImageOpen)}
                >
                  {isChangeImageOpen ? "Zapisz" : "Zmień zdjęcie"}
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setIsPasswordChangeOpen(true)}
                >
                  Zmień hasło
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid xs={6} item>
            <Box
              maxWidth="80%"
              component="section"
              display="flex"
              gap={4}
              flexDirection="column"
            >
              <TextField
                label="Karta wędkarska"
                value={userData?.fishing_card || ""}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                ) => setUserData({ ...userData, fishing_card: e.target.value })}
                size="medium"
              />
              <FormControl>
                <InputLabel>Ulubione łowisko</InputLabel>
                <OutlinedInput
                  size="medium"
                  onClick={() => setIsSpotSelectOpen(true)}
                  readOnly
                  value={
                    !!userData.favorite_fishery?.length
                      ? `${userData.favorite_fishery?.length} ${getFisheriesPluralFormating(userData.favorite_fishery?.length)}`
                      : ""
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <EditIcon
                        onClick={() => setIsSpotSelectOpen(true)}
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    </InputAdornment>
                  }
                  label="Ulubione łowisko"
                />
              </FormControl>

              <FormControl>
                <InputLabel>Ulubiona metoda połowu</InputLabel>
                <Select
                  label="Ulubiona metoda połowu"
                  onChange={(e: SelectChangeEvent<number>) =>
                    setUserData({
                      ...userData,
                      favorite_fishing_method: Number(e.target.value),
                    })
                  }
                  value={userData.favorite_fishing_method || ""}
                >
                  {FAVOURITE_METHODS.map((method) => (
                    <MenuItem key={method.value} value={method.value}>
                      {method.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Członkowstwo PZW/Gospodarstwo Rybackie</InputLabel>
                <OutlinedInput
                  size="medium"
                  value={userData.association_name || ""}
                  onClick={() => setIsAssociationEditOpen(true)}
                  readOnly
                  endAdornment={
                    <InputAdornment position="end">
                      <EditIcon
                        onClick={() => {
                          setIsAssociationEditOpen(true);
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    </InputAdornment>
                  }
                  label="Członkowstwo PZW/Gospodarstwo Rybackie"
                />
              </FormControl>
              {userData.association_club_name && (
                <FormControl>
                  <InputLabel>Koło PZW</InputLabel>
                  <OutlinedInput
                    size="medium"
                    value={userData.association_club_name}
                    onClick={() => setIsAssociationEditOpen(true)}
                    readOnly
                    endAdornment={
                      <InputAdornment position="end">
                        <EditIcon
                          onClick={() => {
                            setIsAssociationEditOpen(true);
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        />
                      </InputAdornment>
                    }
                    label="Kolo PZW"
                  />
                </FormControl>
              )}
            </Box>
          </Grid>
          <Grid xs={6} item>
            {/*<Typography variant="body1">Stan Subksrypcji:</Typography>*/}
          </Grid>
        </Grid>
        <Grid xs={12} item display="flex" justifyContent="flex-end">
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Box mr={2}>
              <Button
                color="error"
                variant="outlined"
                onClick={() => setIsConfirmOpen(true)}
              >
                Usuń konto
              </Button>
            </Box>
            <Box>
              <Button onClick={handleClickSave}>Zapisz zmiany</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <FisherySelectDialog
        fisheries={fisheries}
        isOpen={isFisherySelectOpen}
        selectedFisheries={userData.favorite_fishery}
        selectedAssociation={userData.association_id}
        onClickClose={() => setIsSpotSelectOpen(false)}
        onClickConfirm={(selectedValues) => {
          setIsSpotSelectOpen(false);
          setUserData({ ...userData, favorite_fishery: selectedValues });
        }}
      />
      <AssociationEditDialog
        associations={associations}
        clubs={associationClubs}
        isOpen={isAssociationEditOpen}
        selectedAssociation={{
          association_name: userData.association_name,
          association_id: userData.association_id as DocumentReference,
        }}
        selectedAssociationClub={{
          association_club_name: userData.association_club_name,
          association_club_id:
            userData.association_club_id as DocumentReference,
        }}
        associationCard={userData.association_card}
        onClickSave={handleSaveAssociationDialog}
        onClickClose={() => {
          setIsAssociationEditOpen(false);
        }}
      />

      <ChangePasswordDialog
        isOpen={isPasswordChangeOpen}
        onClickClose={() => setIsPasswordChangeOpen(false)}
        auth={auth}
      />

      <Dialog open={isEditEmailOpen} fullWidth maxWidth="xs">
        <DialogTitle>Zmiana adresu email</DialogTitle>
        <DialogContent>
          {!!emailChangeError.length && (
            <Alert severity="error">{emailChangeError}</Alert>
          )}
          <TextField
            onChange={(e) => setCurrentPassword(e.target.value)}
            label="Hasło"
            variant="standard"
            fullWidth
            type="password"
          />
          <TextField
            label="Nowy Email"
            variant="standard"
            fullWidth
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditEmailOpen(false)}>Zamknij</Button>
          <Button onClick={handleEmailChange}>Zmień</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isConfirmOpen}>
        <DialogTitle>Potwierdzenie usuniecia konta</DialogTitle>
        <DialogContent>
          {!!deleteAccountError.length && (
            <Alert severity="error">{deleteAccountError}</Alert>
          )}
          <DialogContentText>
            Czy jesteś pewny, że chcesz usunać konto? Ta akcja jest
            nieodwracalna.
          </DialogContentText>
          <TextField
            onChange={(e) => setCurrentPassword(e.target.value)}
            label="Podaj Hasło"
            variant="standard"
            fullWidth
            type="password"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmOpen(false)}>Zamknij</Button>
          <Button
            color="error"
            variant="outlined"
            onClick={handleConfirmDeleteAccount}
          >
            Potwierdź
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showToast}
        onClose={() => {
          setToastMessage("");
          setShowToast(false);
        }}
        autoHideDuration={5000}
        message={toastMessage}
      />
    </Layout>
  );
};

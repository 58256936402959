import React, { useEffect, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase-config";
import { useAuth } from "@contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Button, Typography, Box } from "@mui/material";
import { route } from "../consts/routes";

export const LoginPage: React.FC = () => {
  const [error, setError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate(route.HOME);
    }
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(route.HOME);
      console.log("Zalogowano!");
    } catch (error) {
      setError(true);
      console.error("Błąd logowania", error);
    }
  };

  return (
    <Box
      sx={{ width: "100vw", height: "100vh" }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid container sx={{ width: "920px" }}>
        <Grid item xs={4}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src="/logo.png"
              sx={{ height: "auto", width: "210px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            component="form"
            onSubmit={handleLogin}
            width={340}
            display="flex"
            flexDirection="column"
            gap={2}
            textAlign="center"
            sx={{ borderLeft: "1px solid #D9D9D9", padding: "32px" }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Zaloguj się
            </Typography>
            <TextField
              error={error}
              label="Email"
              type="email"
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              error={error}
              label="Hasło"
              type="password"
              size="small"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
              <Typography variant="body2" color="error" textAlign="center">
                Nieprawidłowy e-mail lub hasło
              </Typography>
            )}
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Zaloguj
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

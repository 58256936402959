type TranslationDictionary = { [key: string]: string };

export const translateRanges = <Type extends { label?: string }>(
  dictionary: TranslationDictionary,
) => {
  return (item: Type) =>
    item.label && dictionary[item.label]
      ? { ...item, label: dictionary[item.label] }
      : item;
};

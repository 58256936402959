import { create } from "zustand";

import { UserWithFishing } from "@interfaces/User";

import { UserState } from "@store/useUsersWithFishingStore/interfaces";
import { FishingWithUser } from "@interfaces/Fishing";

export const useUsersWithFishingStore = create<UserState>((set) => ({
  usersWithFishing: [],
  loading: false,
  setLoading: (loading) => set(() => ({ loading })),
  setUsersWithFishing: (usersList: UserWithFishing[]) =>
    set((state: UserState) => ({ ...state, usersList })),
  getUsersWithFishing: (users, fishingsWithUser) => {
    set({ loading: true });

    const usersWithFishing: UserWithFishing[] = users.map((user, index) => {
      return {
        ...user,
        id: index,
        fishings: fishingsWithUser.filter(
          (fishing: FishingWithUser) => fishing.user?.uid === user.uid,
        ).length,
      } as UserWithFishing;
    });

    set({ usersWithFishing, loading: false });
  },
}));

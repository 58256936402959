import { Licence } from "@interfaces/Licences";
import { DataRange } from "@interfaces/FishingClubs";
import { User } from "@interfaces/User";

import { ROLES } from "@store/useUserStore/enums";

import {
  collection,
  doc,
  getDocs,
  Query,
  query,
  where,
} from "firebase/firestore";

import { startOfDay, endOfDay, subMonths } from "date-fns";

import { Collection } from "../enums";
import { tryCatchFunc } from "../helpers";
import { db } from "../../../firebase-config";

export const getLicenceData = async (
  user: User,
  selectedRange?: DataRange,
  finallyFunc: () => void = () => undefined,
): Promise<Licence[]> => {
  if (!user.uid) {
    return [];
  }

  const getLicence = async (): Promise<Licence[]> => {
    const query = licenceQuery(user, selectedRange);
    const licencesSnapshot = await getDocs(query);
    return licencesSnapshot.docs.map((doc) => ({
      ...doc.data(),
    })) as Licence[];
  };
  const catchFunc = (error: unknown): [] => {
    console.error("Failed to fetch licences data:", error);
    return [];
  };

  return tryCatchFunc(getLicence, catchFunc, finallyFunc);
};

const licenceQuery = (user: User, selectedRange?: DataRange): Query => {
  let q = query(collection(db, Collection.LICENCES));

  // data access
  if (user.roles.includes(ROLES.SUPERADMIN)) {
    // all data
    q = query(q);
  } else if (user.roles.includes(ROLES.ADMIN)) {
    // association data only
    q = query(q, where("association", "==", user.association_id));
  } else {
    // user data only
    const userDocRef = doc(db, Collection.USERS, user.uid);
    q = query(q, where("user_id", "==", userDocRef));
  }

  // data range
  const startDate = selectedRange
    ? selectedRange.startDate
    : subMonths(new Date(), 12);
  const endDate = selectedRange ? selectedRange.endDate : new Date();
  q = query(
    q,
    where("start_date", "<=", endOfDay(endDate)),
    where("end_date", ">=", startOfDay(startDate)),
  );

  return q;
};

import { db } from "../../firebase-config";
import {
  DocumentReference,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { tryCatchFunc } from "./helpers";
import { Collection } from "./enums";
import { Fishery } from "@interfaces/Fisheries";

export const getFisheriesByAssociation = async (
  associationDocRef: DocumentReference | null,
  finnalyFunc: () => void = () => undefined,
): Promise<Fishery[]> => {
  const getFishery = async (): Promise<Fishery[]> => {
    const fisheriesQuery = query(
      collection(db, Collection.FISHERIES),
      where("association_id", "==", associationDocRef),
    );

    const fisheriesSnapshot = await getDocs(fisheriesQuery);

    const fisheriesList = fisheriesSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        location: data.location || [],
      };
    }) as Fishery[];
    return fisheriesList;
  };
  const catchFunc = (error: unknown): [] => {
    console.error(`Failed to fetch ${Collection.FISHERIES} data:`, error);
    return [];
  };

  return tryCatchFunc(getFishery, catchFunc, finnalyFunc);
};

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Association } from "@interfaces/Association";
import { AssociationClub } from "@interfaces/AssociationClub";
import { DocumentReference } from "@firebase/firestore";
import { doc } from "firebase/firestore";
import { db } from "../../../firebase-config";

const StyledListItemButton = styled(ListItemButton)({
  paddingLeft: 0,
  paddingRight: 0,
  display: "flex",
  justifyContent: "space-between",
});

const StyledListItemText = styled(ListItemText)({
  paddingLeft: "8px",
  paddingRight: "8px",
});

const StyledListItemIcon = styled(ListItemIcon)({
  display: "flex",
  justifyContent: "center",
});

type SelectedAssociation = {
  association_name: string;
  association_id: DocumentReference | null;
};

type SelectedAssociationClub = {
  association_club_name: string;
  association_club_id: DocumentReference | null;
};

interface Props {
  associations: Association[];
  clubs?: AssociationClub[];
  isOpen: boolean;
  selectedAssociation: SelectedAssociation | null;
  selectedAssociationClub: SelectedAssociationClub | null;
  associationCard: string;
  onClickClose: () => void;
  onClickSave: (values: AssociationDialogPayload) => void;
}

export interface AssociationDialogPayload
  extends SelectedAssociation,
    SelectedAssociationClub {
  association_card: string;
}

const AssociationEditDialog = ({
  associations,
  clubs,
  isOpen,
  selectedAssociation,
  selectedAssociationClub,
  onClickClose,
  onClickSave,
  associationCard,
}: Props) => {
  const [searchAssociationText, setSearchAssociationText] = useState("");
  const [searchClubText, setSearchClubText] = useState("");
  const [isAssociationNextStep, setIsAssociationNextStep] = useState(false);
  const [values, setValues] = useState<AssociationDialogPayload>({
    association_name: selectedAssociation?.association_name ?? "",
    association_id: selectedAssociation?.association_id ?? null,
    association_club_name: selectedAssociationClub?.association_club_name ?? "",
    association_club_id: selectedAssociationClub?.association_club_id ?? null,
    association_card: associationCard,
  });

  useEffect(() => {
    setValues({
      association_name: selectedAssociation?.association_name ?? "",
      association_id: selectedAssociation?.association_id ?? null,
      association_club_name:
        selectedAssociationClub?.association_club_name ?? "",
      association_club_id: selectedAssociationClub?.association_club_id ?? null,
      association_card: associationCard,
    });

    setIsAssociationNextStep(false);
    setSearchClubText("");
    setSearchAssociationText("");
  }, [
    selectedAssociation?.association_name,
    selectedAssociationClub?.association_club_name,
    isOpen,
  ]);

  const associationClubs = clubs?.filter((club) => {
    if (values.association_id instanceof DocumentReference) {
      if (values.association_id?.id && club.association?.id) {
        return club.association.id === values.association_id?.id;
      }
    }
  });

  const handleOnSearchChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setFn: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setFn(e.target.value);
  };

  const searchValue = isAssociationNextStep
    ? searchClubText
    : searchAssociationText;

  const searchFn = isAssociationNextStep
    ? setSearchClubText
    : setSearchAssociationText;

  const searchAssociationsResult = associations
    .filter(({ name }) => {
      return (
        name && name.toLowerCase().includes(searchAssociationText.toLowerCase())
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const searchClubsResult = !associationClubs
    ? []
    : associationClubs
        .filter(({ name }) => {
          return (
            name && name.toLowerCase().includes(searchClubText.toLowerCase())
          );
        })
        .sort((a, b) => a.name.localeCompare(b.name));

  const handleAssociationSelect = (association: Association) => {
    const associationRef = doc(db, "associations", association.id as string);
    setValues({
      ...values,
      association_id: associationRef,
      association_name: association.name,
    });
  };

  const handleFisherySelect = (club: AssociationClub) => {
    const associationRef = doc(db, "association_clubs", club.id);
    setValues({
      ...values,
      association_club_id: associationRef,
      association_club_name: club.name,
    });
  };
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>Wybierz swój Okręg PZW / Gospodarstwo Rybackie</DialogTitle>
      <DialogContent>
        {isAssociationNextStep && (
          <Chip
            size="medium"
            label={values?.association_name}
            sx={{
              marginBottom: "8px",
            }}
          ></Chip>
        )}
        {!isAssociationNextStep && (
          <TextField
            label="Numer Legitymacji członkowskiej"
            variant="standard"
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) =>
              setValues({
                ...values,
                association_card: e.target.value,
              })
            }
            value={values.association_card}
            fullWidth
          />
        )}
        <TextField
          label="Filtruj..."
          variant="standard"
          fullWidth
          value={searchValue}
          onChange={(e) => handleOnSearchChange(e, searchFn)}
        />
        <List>
          {!isAssociationNextStep &&
            searchAssociationsResult.map((association) => (
              <StyledListItemButton
                key={association.name}
                onClick={() => handleAssociationSelect(association)}
              >
                <StyledListItemText>{association.name}</StyledListItemText>
                <StyledListItemIcon>
                  <Checkbox
                    checked={association.name === values?.association_name}
                  />
                </StyledListItemIcon>
              </StyledListItemButton>
            ))}
          {isAssociationNextStep &&
            searchClubsResult?.map((club) => (
              <StyledListItemButton
                key={club.club_id}
                onClick={() => handleFisherySelect(club)}
              >
                <StyledListItemText>{club.name}</StyledListItemText>
                <StyledListItemIcon>
                  <Checkbox
                    checked={
                      club.id ===
                      (values.association_club_id as DocumentReference)?.id
                    }
                  />
                </StyledListItemIcon>
              </StyledListItemButton>
            ))}
        </List>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: isAssociationNextStep ? "space-between" : "flex-end",
        }}
      >
        {isAssociationNextStep && (
          <Button
            variant="outlined"
            onClick={() => {
              setIsAssociationNextStep(false);
              setSearchClubText("");
            }}
          >
            <ArrowBackIcon sx={{ marginRight: "8px" }} />
            Lista okręgów
          </Button>
        )}
        <Box>
          <Button
            sx={{
              marginRight: "8px",
            }}
            onClick={() => {
              setIsAssociationNextStep(false);
              onClickClose();
            }}
          >
            Zamknij
          </Button>
          <Button
            disabled={
              !values.association_name || values.association_card.length === 0
            }
            variant="contained"
            onClick={() => {
              if (isAssociationNextStep) {
                onClickSave(values);
                onClickClose();
              } else {
                setIsAssociationNextStep(true);
              }
            }}
          >
            {isAssociationNextStep ? "Zatwierdź" : "Dalej"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AssociationEditDialog;

import React from "react";
import Box from "@mui/material/Box";
import Header from "../Header";
import Navigation from "../Nav";
import "./styles.scss";
import SnackbarComponent from "@components/Snackbar";
import Dialog from "@components/Dialog";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const drawerWidth = 260;

  return (
    <Box display="flex" className="main">
      <Header width={`calc(100vw - ${drawerWidth}px)`} />

      <Navigation drawerWidth={drawerWidth} />
      <Box
        component="main"
        display="flex"
        sx={{
          flexGrow: 1,
          p: 3,
          height: "calc(100vh - 64px)",
          width: `calc(100vw - ${drawerWidth}px)`,
          marginTop: "64px",
          boxSizing: "border-box",
        }}
      >
        {children}
      </Box>
      <Dialog />
      <SnackbarComponent />
    </Box>
  );
};

export default Layout;

import {
  AggregatedProduct,
  FishingClubReportData,
} from "@interfaces/FishingClubs";
import {
  extractOptionName,
  generateKeyWithPrice,
  isProductAnnualLicense,
} from "../helpers";
import { LICENCE_FEE, ProductType } from "../../consts/transaction";

export const groupAndAggregateProducts = (
  products: FishingClubReportData[],
): AggregatedProduct[] => {
  const aggregationMap = new Map<string, AggregatedProduct>();

  products.forEach((product: FishingClubReportData, i) => {
    const isAnnual = isProductAnnualLicense(product);
    if (!isAnnual) return;

    let aggregatedInfo = aggregationMap.get(product.clubId);

    if (!aggregatedInfo) {
      aggregatedInfo = {
        id: i + 1,
        clubId: product.clubId,
        clubName: product.clubName,
        products: [],
        discountsCount: {},
        licenseOptionsCount: {},
        entryFeeCount: {},
        licenceCount: 0,
        licenceCountWithDiscount: 0,
        totalPrice: 0,
      };
      aggregationMap.set(product.clubId, aggregatedInfo);
    }

    aggregatedInfo.products.push(product);
    aggregatedInfo.totalPrice += product.price;

    if (
      (!product.discounts || product.discounts.length === 0) &&
      product.type === ProductType.ASSOCIATION_FEE &&
      product.price !== 0
    ) {
      aggregatedInfo.licenceCount += 1;
    }

    if (
      product.discounts &&
      product.discounts.length > 0 &&
      product.type === ProductType.ASSOCIATION_FEE &&
      product.price !== 0
    ) {
      aggregatedInfo.licenceCountWithDiscount += 1;
    }

    if (product.entryFee && product.type === ProductType.ASSOCIATION_FEE) {
      let finalPrice = product.price - LICENCE_FEE;
      if (product.discounts && product.discounts.length > 0) {
        const discountValue = product.discounts[0].value;
        finalPrice += discountValue;
      }

      const entryFeeKey = generateKeyWithPrice("entryFee", finalPrice);

      if (aggregatedInfo) {
        aggregatedInfo.entryFeeCount[entryFeeKey] =
          (aggregatedInfo.entryFeeCount[entryFeeKey] || 0) + 1;
      }
    }

    if (product.type === ProductType.LICENSE) {
      aggregatedInfo.licenseOptionsCount[
        generateKeyWithPrice(
          extractOptionName(product.licenseOption),
          product.price,
        )
      ] =
        (aggregatedInfo.licenseOptionsCount[
          generateKeyWithPrice(
            extractOptionName(product.licenseOption),
            product.price,
          )
        ] || 0) + 1;
    }

    product.discounts?.forEach(({ name }) => {
      if (product.type === ProductType.ASSOCIATION_FEE) {
        let finalPrice = product.price;
        if (product.entryFee) {
          const discountValue = product.discounts[0].value;
          const price = product.price + discountValue - LICENCE_FEE;
          finalPrice -= price;
        }

        const discountKey = generateKeyWithPrice(name, finalPrice);

        if (aggregatedInfo) {
          aggregatedInfo.discountsCount[discountKey] =
            (aggregatedInfo.discountsCount[discountKey] || 0) + 1;
        }
      }
    });
  });

  return Array.from(aggregationMap.values());
};

export const STATIC_RANGES_LABELS = {
  Today: "Dzisiaj",
  Yesterday: "Wczoraj",
  "This Week": "Ten tydzień",
  "Last Week": "Poprzedni tydzień",
  "This Month": "Ten miesiąc",
  "Last Month": "Poprzedni miesiąc",
};

export const INPUT_RANGES_LABELS = {
  "days up to today": "dni do dzisiaj",
  "days starting today": "dni od dzisiaj",
};

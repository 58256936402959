import { db } from "../../firebase-config";
import { getDoc, writeBatch, doc } from "firebase/firestore";
import { DocumentReference } from "@firebase/firestore";

export interface UserData {
  favorite_fishing_method?: number | null;
  favorite_fishery?: DocumentReference[] | null;
  fishing_card?: string;
  association_card?: string;
  association_club_id?: DocumentReference | null;
  association_club_name?: string;
  association_id?: DocumentReference | null;
  association_name?: string;
  display_name?: string;
  email?: string;
  photo_url?: string;
}

export const updateUserData = async (
  userId: string,
  userData: UserData,
  finallyFunc: () => void = () => undefined,
) => {
  if (!userId) return;

  const batch = writeBatch(db);
  const userRef = doc(db, "users", userId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    Object.entries(userData).forEach(([key, value]) => {
      const snapshotValue = userSnap.data()[key as keyof UserData];
      if (
        snapshotValue instanceof DocumentReference &&
        value.id !== userSnap.data()[key as keyof UserData].id
      ) {
        batch.update(userRef, { [key]: value });
      } else {
        if (value && value !== snapshotValue) {
          batch.update(userRef, { [key]: value });
        }
      }
    });
  }

  await batch.commit().finally(finallyFunc);
};

import { create } from "zustand";
import { DEFAULT_FISHING_STATE } from "@store/useFishingStore/consts";
import { FishingState } from "@store/useFishingStore/interfaces";
import { getFishingData } from "@utils/api/getFishingData";

export const useFishingStore = create<FishingState>((set) => ({
  ...DEFAULT_FISHING_STATE,

  reset: () => set(DEFAULT_FISHING_STATE),
  setLoading: (loading) => set(() => ({ loading })),
  getFishing: async (user, users, selectedRange) => {
    set({ loading: true });
    const rawFishing = await getFishingData(user, users, selectedRange);

    set({ fishing: rawFishing, loading: false });
  },
}));

import { db } from "../../firebase-config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { tryCatchFunc } from "./helpers";
import { Collection } from "./enums";
import { SsrControl } from "@interfaces/SsrControl";

export const fetchControls = async (
  association_name: string,
  finallyFunc: () => void = () => undefined,
): Promise<SsrControl[]> => {
  if (!association_name.length) return [];
  const getControls = async (): Promise<SsrControl[]> => {
    const controlsQuery = query(
      collection(db, Collection.SSR),
      where("association_name", "==", association_name),
    );

    const rangersQuery = query(
      collection(db, Collection.USERS),
      where("roles", "array-contains", "ranger"),
    );

    const usersQuery = query(collection(db, Collection.USERS));

    const ssrSnapshot = await getDocs(controlsQuery);
    const rangerSnapshot = await getDocs(rangersQuery);
    const usersSnapshot = await getDocs(usersQuery);

    return ssrSnapshot.docs.map((doc) => {
      const data = doc.data();
      let controlledUsed;
      const controllerEmail = rangerSnapshot.docs.find(
        (ranger) => ranger.id === data.controller_id,
      );

      if (data.user_id) {
        controlledUsed = usersSnapshot.docs.find(
          (user) => user.id === data.user_id.id,
        );
      }

      return {
        ...data,
        controller_email: controllerEmail?.data().email ?? "",
        controller_name: controllerEmail?.data().display_name ?? "",
        user_email: controlledUsed?.data().email ?? "",
        association_card: controlledUsed?.data().association_card ?? "",
        id: doc.id,
      };
    }) as SsrControl[];
  };
  const catchFunc = (error: unknown): [] => {
    console.error(`Failed to fetch ${Collection.FISHERIES} data:`, error);
    return [];
  };

  return tryCatchFunc(getControls, catchFunc, finallyFunc);
};

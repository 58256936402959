import {
  Query,
  query,
  collection,
  getCountFromServer,
} from "firebase/firestore";

import { db } from "../../../../src/firebase-config";

export const getCollectionCount = async (name: string): Promise<number> => {
  const q: Query = query(collection(db, name));
  const countSnapshot = await getCountFromServer(q);

  return countSnapshot.data().count || -1;
};

import { Marker as LeafletMarker } from "react-leaflet";
import { LatLng, Icon } from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
interface Props {
  lat: number;
  lng: number;
  children?: React.ReactNode;
}
const Marker = ({ lat, lng, children = null }: Props) => (
  <LeafletMarker
    icon={new Icon({ iconUrl: icon })}
    position={new LatLng(lat, lng)}
  >
    {children}
  </LeafletMarker>
);

export default Marker;

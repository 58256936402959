export const route = {
  HOME: "/",
  LOGIN: "/login",
  FISHING: "/fishing",
  FISHING_CLUBS: "/fishing-clubs",
  LICENCES: "/licences",
  TRANSACTIONS: "/transactions",
  FISHERIES: "/fisheries",
  USERS_SUMMARY: "/users-summary",
  USERS: "/users",
  PERIODIC_PERMITS: "/periodic-permits",
  PROFILE: "/profile",
  MAINTENANCE: "/maintenance",
  RANGER_REPORT: "/ranger-report",
};

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CustomDatePicker from "../CustomDatePicker";
import { format } from "date-fns";
import { DEFAULT_START_END_DATE } from "../../consts/date";

const DatePickerDialog = ({
  onConfirm,
  fullWidth = false,
}: {
  onConfirm: (range: { startDate: Date; endDate: Date }) => void;
  fullWidth?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState({
    ...DEFAULT_START_END_DATE,
    key: "selection",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    onConfirm(range);
    handleClose();
  };

  const formattedDate = `${format(range.startDate, "dd/MM/yyyy")} - ${format(range.endDate, "dd/MM/yyyy")}`;

  return (
    <>
      <TextField
        label="Wybierz zakres dat"
        value={formattedDate}
        onClick={handleOpen}
        variant="outlined"
        sx={{ width: 240 }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth="lg"
      >
        <DialogTitle>Wybierz zakres dat</DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <CustomDatePicker range={range} setRange={setRange} months={2} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button onClick={handleConfirm}>Zatwierdź</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DatePickerDialog;

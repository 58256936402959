import { PositionHorizontal, PositionVertical, SnackbarVariant } from "./enums";

export const DEFAULT_AUTO_HIDE_DURATION = 5000;

export const DEFAULT_SNACKBAR_STATE = {
  message: "",
  isSnackbarVisible: false,
  autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
  anchorOrigin: {
    vertical: PositionVertical.BOTTOM,
    horizontal: PositionHorizontal.LEFT,
  },
  variant: SnackbarVariant.DEFAULT,
};

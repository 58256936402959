import { useState, useEffect } from "react";
import { Fishery } from "@interfaces/Fisheries";
import { getFisheriesData } from "@utils/api/getFisheriesData";

interface UseFisheriesDataReturn {
  fisheries: Fishery[];
  loading: boolean;
}

const useFisheriesData = (): UseFisheriesDataReturn => {
  const [fisheries, setFisheries] = useState<Fishery[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFisheries = async () => {
      const rawFisheries = await getFisheriesData();
      setFisheries(rawFisheries);
      setLoading(false);
    };

    fetchFisheries().catch(console.error);
  }, []);

  return { fisheries, loading };
};

export default useFisheriesData;

import { User } from "@interfaces/User";
import { DocumentReference, getDoc } from "firebase/firestore";

export const getAssociationUser = async (docRef?: DocumentReference) => {
  if (!docRef) return null;

  const docSnap = await getDoc(docRef);

  return docSnap.exists() ? (docSnap.data() as User) : null;
};

import { User } from "@interfaces/User";
import { DataRange } from "@interfaces/FishingClubs";

import { getTransactionData } from "@utils/api/transactions/getTransactionData";
import { transformFishingClubsData } from "@utils/transformFishingClubsData";
import { transformTransactions } from "@utils/transactions/transformTransactions";

import { create } from "zustand";

import { TransactionStatus } from "../../consts/transaction";

import { DEFAULT_TRANSACTIONS_STATE } from "./consts";
import { TransitionsState } from "./interfaces";

export const useTransactionsStore = create<TransitionsState>((set) => ({
  ...DEFAULT_TRANSACTIONS_STATE,

  reset: () => set(DEFAULT_TRANSACTIONS_STATE),
  setLoading: (loading) => set(() => ({ loading })),
  getTransactions: async (user: User, selectedRange?: DataRange) => {
    set({ loading: true });
    const rawTransactions = await getTransactionData(user, selectedRange);
    if (rawTransactions && rawTransactions.length) {
      const data = await transformTransactions(rawTransactions);
      set({ transactionsGroup: data, loading: false });
    } else {
      set({ transactionsGroup: [], loading: false });
    }
  },
  getProducts: async (user: User, selectedRange?: DataRange) => {
    set({ loading: true });
    const validStatuses: TransactionStatus[] = [
      TransactionStatus.PAID,
      TransactionStatus.COMPLETED,
      TransactionStatus.EMAIL_SENT,
    ];
    const rawTransactions = await getTransactionData(
      user,
      selectedRange,
      validStatuses,
    );

    if (rawTransactions && rawTransactions.length) {
      const products = rawTransactions.flatMap(({ products }) =>
        products.map((product) => product),
      );

      set({ transactions: rawTransactions, products, loading: false });
    } else {
      set({ transactions: [], products: [], loading: false });
    }
  },
  getFishingClubs: async (user: User, selectedRange?: DataRange) => {
    set({ loading: true });
    const validStatuses: TransactionStatus[] = [TransactionStatus.EMAIL_SENT];

    const rawTransactions = await getTransactionData(
      user,
      selectedRange,
      validStatuses,
    );

    if (rawTransactions && rawTransactions.length) {
      const data = await transformFishingClubsData(rawTransactions);
      set({ fishingClubs: data, loading: false });
    } else {
      set({
        fishingClubs: {
          entryFeeColumns: [],
          groupedProducts: [],
          licenseColumns: [],
          licenseWithDiscountColumns: [],
        },
        loading: false,
      });
    }
  },
}));

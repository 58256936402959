import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth } from "../firebase-config";
import { onAuthStateChanged, User } from "firebase/auth";
import { getUserData } from "@utils/getUserData";
import { useUserStore } from "@store/useUserStore/useUserStore";
import { DEFAULT_ROLES, DEFAULT_VERSION } from "@store/useUserStore/consts";

type AuthContextType = {
  currentUser: User | null;
  loading: boolean;
};

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  loading: true,
});

type AuthProviderProps = {
  children: ReactNode;
};

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const { setUser } = useUserStore();

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const userData = await getUserData(currentUser?.uid);

      setUser({
        uid: userData?.uid || "",
        email: userData?.email || "",
        roles: userData?.roles || DEFAULT_ROLES,
        association_id: userData?.association_id || null,
        app_version: userData?.app_version || DEFAULT_VERSION,
        has_app_version: userData?.app_version !== undefined,
        association_name: userData?.association_name || "",
        association_card: userData?.association_card || "",
        association_club_id: userData?.association_club_id || null,
        association_club_name: userData?.association_club_name || "",
        favorite_fishery: userData?.favorite_fishery || null,
        favorite_fishing_method: userData?.favorite_fishing_method || null,
        fishing_card: userData?.fishing_card || "",
        display_name: userData?.display_name || "",
        photo_url: userData?.photo_url || "",
      });
    };
    fetchData().catch(console.error);
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

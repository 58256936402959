import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid, GridColDef, GridToolbar, plPL } from "@mui/x-data-grid";
import useUsersSummary from "../hooks/useUsersAssociationNameSummary";
import useUsersClubNameSummary from "../hooks/useUsersAssociationClubNameSummary";
import { Typography } from "@mui/material";
import DatePickerDialog from "@components/DatePickerDialog";
import { DataRange } from "@interfaces/FishingClubs";
import { DEFAULT_START_END_DATE } from "src/consts/date";

interface AssociationNameRow {
  associationName: string;
  usersCount: number;
  id: number;
}

interface AssociationClubNameRow {
  associationClubName: string;
  usersCount: number;
  id: number;
}

export const AssociationNameUsersColumns: GridColDef[] = [
  { field: "associationName", headerName: "Okręg PZW", width: 1000 },
  {
    field: "usersCount",
    headerName: "Liczba użytkowników",
    width: 200,
  },
];

export const AssociationClubNameUsersColumns: GridColDef[] = [
  { field: "associationClubName", headerName: "Koło PZW", width: 1000 },
  {
    field: "usersCount",
    headerName: "Liczba użytkowników",
    width: 200,
  },
];

export const UsersSummaryPage: React.FC = () => {
  const [selectedRange, setSelectedRange] = useState<DataRange>(
    DEFAULT_START_END_DATE,
  );

  const { usersCount, associationsNameUsersCount } =
    useUsersSummary(selectedRange);
  const { associationsClubNameUsersCount } = useUsersClubNameSummary();
  const [associationNameRows, setAssociationNameRows] = useState<
    AssociationNameRow[]
  >([]);
  const [associationClubNameRows, setAssociationClubNameRows] = useState<
    AssociationClubNameRow[]
  >([]);

  const handleConfirmDialogDateRange = (range: DataRange) => {
    setSelectedRange(range);
  };

  useEffect(() => {
    const fetchAndProcessNameSummary = async () => {
      const newRows = Object.entries(associationsNameUsersCount).map(
        ([key, value], index) => ({
          associationName: key,
          usersCount: value,
          id: index,
        }),
      );
      setAssociationNameRows(newRows);
    };

    const fetchAndProcessClubNameSummary = async () => {
      const newRows = Object.entries(associationsClubNameUsersCount).map(
        ([key, value], index) => ({
          associationClubName: key,
          usersCount: value,
          id: index,
        }),
      );
      setAssociationClubNameRows(newRows);
    };

    fetchAndProcessNameSummary().catch(console.error);
    fetchAndProcessClubNameSummary().catch(console.error);
  }, [usersCount, associationsNameUsersCount]);

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ marginTop: 40, marginBottom: 20 }}>
            <Typography variant="body1">
              Suma założonych kont w wybranym zakresie dat: <b>{usersCount}</b>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <DatePickerDialog onConfirm={handleConfirmDialogDateRange} />
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ width: "100%", height: 760, backgroundColor: "white" }}
          >
            <DataGrid
              disableRowSelectionOnClick
              rows={associationNameRows}
              loading={false}
              columns={AssociationNameUsersColumns}
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                },
              }}
              pageSizeOptions={[15, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ width: "100%", height: 760, backgroundColor: "white" }}
          >
            <DataGrid
              disableRowSelectionOnClick
              rows={associationClubNameRows}
              loading={false}
              columns={AssociationClubNameUsersColumns}
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                },
              }}
              pageSizeOptions={[15, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

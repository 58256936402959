import React from "react";
import Box from "@mui/material/Box";
import { FishingClubReportData, Option } from "@interfaces/FishingClubs";
import { formatAmount, generateKeyWithPrice } from "../helpers";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { extractProductAssociationFeeWithDiscount } from "@utils/fishingClubs/helpers";
import { Typography } from "@mui/material";

export const extractLicenseWithDiscountColumns = (
  products: FishingClubReportData[],
) => {
  const optionsMap: Map<string, Option> = new Map();
  extractProductAssociationFeeWithDiscount(products, optionsMap);

  return Array.from(optionsMap.values()).map(
    (discount): GridColDef => ({
      field: generateKeyWithPrice(discount.name, discount.value),
      headerName: `Składka członkowska z ulgą: ${discount.name} (${formatAmount(discount.value)} zł)`,
      width: discount.name.length * 9,
      valueGetter: (params: GridValueGetterParams) => {
        return (
          params.row.discountsCount?.[
            generateKeyWithPrice(discount.name, discount.value)
          ] ?? ""
        );
      },
      renderHeader: () => (
        <Box
          sx={{ display: "flex", flexDirection: "column", lineHeight: "1.4" }}
        >
          {discount && (
            <Typography variant="caption">Ulga: {discount.name}</Typography>
          )}
          <strong>Składka członkowska</strong>
          <span>({formatAmount(discount.value)} zł)</span>
        </Box>
      ),
    }),
  );
};

import { getCollectionDocs } from "@utils/api/collections";
import { Collection } from "@utils/api/enums";

import { doc, updateDoc } from "firebase/firestore";

import { db } from "../../../firebase-config";

export const updateAssociationsByProduct = async (collectionName: string) => {
  const allDocs = await getCollectionDocs(collectionName);

  const updated = allDocs.forEach((item) => {
    const associationId = item.products[0].associationId ?? null;
    const associationRef = doc(db, Collection.ASSOCATIONS, associationId);

    return updateDoc(item.docRef, { association: associationRef });
  });

  return updated;
};

import React, { useEffect, useState } from "react";
import { Fishing } from "@interfaces/Fishing";
import { DataRange } from "@interfaces/Date";
import { FishCountBySpecies } from "@interfaces/Charts";
import { transformDataPieChartNumberFishCaught } from "@utils/charts";
import Loading from "@components/Loading";
import { CardComponent } from "@components/Card";
import { areElementsInArray } from "@utils/arrayUtils";
import { Box, Chip } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

interface BarChartNumberFishCaughtProps {
  loading: boolean;
  data: Fishing[];
  selectedRange: DataRange;
}
export const BarChartNumberFishCaught: React.FC<
  BarChartNumberFishCaughtProps
> = ({ loading, data, selectedRange }) => {
  const [barChartData, setBarChartData] = useState<FishCountBySpecies[] | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    if (!loading) {
      const transformedData = transformDataPieChartNumberFishCaught(
        data,
        selectedRange,
      );
      setBarChartData(transformedData);
      setIsLoading(false);
    }
  }, [loading, data, selectedRange]);

  const count: number[] = (barChartData || []).map((item) => item.count);
  const species: string[] = (barChartData || []).map((item) => item.species);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CardComponent title="Ilość złowionych ryb wg gatunku">
      {!areElementsInArray(species) || !areElementsInArray(count) ? (
        <Box display="flex" sx={{ width: "100%", justifyContent: "center" }}>
          <Chip
            label="Brak danych w wybranym zakresie"
            color="info"
            variant="outlined"
          />
        </Box>
      ) : (
        <BarChart
          xAxis={[{ data: [...species], scaleType: "band" }]}
          series={[{ data: [...count] }]}
          height={260}
        />
      )}
    </CardComponent>
  );
};

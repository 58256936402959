import { useState, useEffect } from "react";
import { Fishing } from "@interfaces/Fishing";
import { getFishingData } from "@utils/api/getFishingData";
import { useUserStore } from "@store/useUserStore/useUserStore";
import { DataRange } from "@interfaces/Date";
import { User } from "@interfaces/User";

interface UseFishingDataReturn {
  fishing: Fishing[];
  loading: boolean;
}

const useFishingData = (
  selectedRange?: DataRange | null,
  filterFishingsByUsers: User[] | null = null,
): UseFishingDataReturn => {
  const [fishing, setFishing] = useState<Fishing[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUserStore();

  useEffect(() => {
    getFishingData(user, filterFishingsByUsers, selectedRange)
      .then((response: Fishing[]) => {
        setFishing(response);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.warn("Failed to use fishing data:", e);
      });
  }, [user, filterFishingsByUsers, selectedRange]);

  return { fishing, loading };
};

export default useFishingData;

import {
  Query,
  query,
  collection,
  getDocs,
  DocumentData,
} from "firebase/firestore";

import { db } from "../../../../src/firebase-config";

export const getCollectionDocs = async (
  name: string,
): Promise<DocumentData[]> => {
  const q: Query = query(collection(db, name));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs
    ? querySnapshot.docs.map((doc) => ({ ...doc.data(), docRef: doc.ref }))
    : [];
};

import { route } from "../consts/routes";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(route.HOME);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100vw",
        height: "100vh",
        flexFlow: "wrap column",
        gap: "24px",
        backgroundColor: "#F5F5F5",
      }}
    >
      <Grid container sx={{ gap: "24px", width: "920px" }}>
        <Grid item xs={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={window.location.origin + "/logo.png"}
              sx={{ display: "flex", maxWidth: "210px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
              gap: "14px",
              borderLeft: "1px solid #D9D9D9",
              padding: "32px",
            }}
          >
            <Typography variant="h1" sx={{ fontWeight: "bold" }}>
              404
            </Typography>
            <Typography variant="body1">
              Strona o podanym adresie nie istnieje.
            </Typography>
            <Button variant="outlined" onClick={handleClick}>
              Strona główna
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

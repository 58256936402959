import { useState, useEffect } from "react";
import useAssociationClub from "./useAssociationClubData";
import { useUserStore } from "@store/useUserStore/useUserStore";

export interface AssociationsNameUsersCount {
  [key: string]: number;
}

interface UseFishingDataReturn {
  usersCount: number;
  associationsClubNameUsersCount: AssociationsNameUsersCount;
}

const DEFAULT_NAME = "Brak okręgu PZW";

const useUsersSummary = (): UseFishingDataReturn => {
  const { associationClubs } = useAssociationClub();
  const { users } = useUserStore();

  const [usersCount, setUsersCount] = useState(0);
  const [associationsClubNameUsersCount, setAssociationsUsersCount] = useState(
    {},
  );

  useEffect(() => {
    const fetchFishingWithUser = () => {
      const result = users.reduce((acc, user) => {
        acc[user.association_club_name || DEFAULT_NAME] =
          acc[user.association_club_name || DEFAULT_NAME] + 1 || 1;
        return acc;
      }, {} as AssociationsNameUsersCount);

      setUsersCount(users?.length);
      setAssociationsUsersCount(result);
    };

    fetchFishingWithUser();
  }, [associationClubs, users]);

  return { usersCount, associationsClubNameUsersCount };
};

export default useUsersSummary;

import { LatLng } from "leaflet";
import { Coordinates } from "@interfaces/Coordinates";
import { areElementsInArray } from "./arrayUtils";

export const getLocationBounds = (
  location: Coordinates[],
): [number, number][] | undefined => {
  if (location && areElementsInArray(location)) {
    return location.map((location) => [location.lat, location.lng]);
  }
};

export const getFirstLocation = (location: Coordinates[]) => {
  if (location && location.length) {
    return new LatLng(location[0].lat, location[0].lng);
  }
};

export const getGoogleMapsUrl = (lat: number, lng: number) =>
  `https://www.google.com/maps/place/${lat},${lng}`;

import { Timestamp } from "@firebase/firestore";

import { FishingClubReportData } from "@interfaces/FishingClubs";
import { Product } from "@interfaces/Transactions";

import { differenceInCalendarDays, format, isValid } from "date-fns";

import { StatusNames } from "../consts/transaction";

export const formatAmount = (amount: number) => amount / 100;

export const newDate = (date: Timestamp, timezoneOffset?: number) =>
  new Date(
    date.seconds * 1000 - (timezoneOffset ? timezoneOffset * 3600 * 1000 : 0),
  );

export const parseTimestamp = (date: Timestamp, formatDate: string) => {
  if (date?.seconds) {
    const newDate = new Date(date.seconds * 1000);
    return isValid(newDate) ? format(newDate, formatDate) : "";
  }
  return "";
};

export const validDate = (date: Date, formatDate: string) =>
  isValid(date) ? format(date, formatDate) : "";

export const generateKey = (name: string) => name.replace(/\s+/g, "_");

export const extractOptionName = (arrayString: string) => {
  return arrayString.split(";")[0];
};

export const generateKeyWithPrice = (name: string, price: number) => {
  const firstString = generateKey(name);
  return `${firstString}_${price}`;
};

export const isProductAnnualLicense = (
  product: Product | FishingClubReportData,
): boolean => {
  const start = new Date(product.startDate.seconds * 1000);
  const end = new Date(product.endDate.seconds * 1000);
  const daysDifference = differenceInCalendarDays(end, start);

  return daysDifference >= 365;
};

export const isLocalHost = (
  hostname: string = window.location.hostname,
): boolean => ["localhost", "127.0.0.1", "::1"].includes(hostname);

export const statusName = (status: string): StatusNames | undefined =>
  Object.entries(StatusNames).find(([key]) => key === status)?.[1];

import { Discounts, Product, Transaction } from "@interfaces/Transactions";

import { formatAmount, newDate } from "@utils/helpers";
import { getAssociationClubs } from "@utils/getAssociationClubs";
import { getAssociations } from "@utils/getAssociationsData";

import { ProductType, TransactionStatus } from "../../consts/transaction";

export const transformTransactions = async (transactions: Transaction[]) => {
  const associations = await getAssociations();
  const associationClubs = await getAssociationClubs();

  const allProcessedProductsPromises = transactions.reduce(
    async (accPromise, transaction) => {
      const acc = await accPromise;

      if (transaction.status === TransactionStatus.EMAIL_SENT) {
        const associationFeesMap = new Map();
        transaction.products.forEach((p) => {
          if (p.type === ProductType.ASSOCIATION_FEE) {
            associationFeesMap.set(p.itemId, p);
          }
        });

        const productsWithTransactionId = await Promise.all(
          transaction.products.map(async (product) => {
            const transactionDate = newDate(transaction.updatedAt);
            const licenseStartDate = newDate(product.startDate);
            const licenseEndDate = newDate(product.endDate);

            const association = associations.find(
              (item) => item.id == product.associationId,
            );
            const associationClub = associationClubs.find(
              (item) => item.club_id == product.associationId,
            );

            if (product.type === ProductType.LICENSE) {
              const associatedFee = associationFeesMap.get(product.itemId);
              const associationDiscount = (associatedFee?.discounts || []).map(
                (item: Discounts) => item.name,
              );
              const licenseDiscount = (product?.discounts || []).map(
                (item: Discounts) => item.name,
              );
              const finalPrice = associatedFee
                ? associatedFee.price + product.price
                : transaction.amount;

              return {
                ...product,
                associationName: association?.name,
                associationFee: "Nie",
                ...(associatedFee
                  ? {
                      associationFee: "Tak",
                      associationDiscount: associationDiscount,
                      associationEntryFee: associatedFee.entryFee
                        ? "Tak"
                        : "Nie",
                      associationPrice: formatAmount(associatedFee.price),
                      associationCard: associatedFee.associationCard,
                      associationClub: associationClub && associationClub.name,
                      associationClubNumber:
                        associationClub && associationClub.club_id,
                    }
                  : {}),
                transactionId: transaction.id,
                currency: transaction.currency,
                orderId: transaction.orderId,
                status: transaction.status,
                transactionDate: transactionDate,
                email: transaction.email,
                userName: product.user.name,
                fishingCard: product.user.fishingCard,
                isForMe: product.user.isForMe ? "Tak" : "Nie",
                isForeigner: product.user.isForeigner ? "Tak" : "Nie",
                isMemberParticipant: product.user.isMemberParticipant
                  ? "Tak"
                  : "Nie",
                licenseOption: product.licenseOption,
                licensePrice: formatAmount(product.price),
                licenseDiscount: licenseDiscount,
                licenseStartDate: licenseStartDate,
                licenseEndDate: licenseEndDate,
                amount: formatAmount(finalPrice),
              };
            }

            if (
              product.type === ProductType.ASSOCIATION_FEE &&
              !transaction.products.some(
                (p) =>
                  p.type === ProductType.LICENSE && p.itemId === product.itemId,
              )
            ) {
              const associatedFee = associationFeesMap.get(product.itemId);

              const associationDiscount = (associatedFee?.discounts || []).map(
                (item: Discounts) => item.name,
              );

              return {
                ...product,
                transactionId: transaction.id,
                associationName: association?.name,
                currency: transaction.currency,
                orderId: transaction.orderId,
                status: transaction.status,
                transactionDate: transactionDate,
                email: transaction.email,
                amount: formatAmount(transaction.amount),
                associationFee: "Nie",
                associationDiscount: associationDiscount,
                associationPrice: formatAmount(associatedFee.price),
                associationCard: associatedFee.associationCard,
                associationClub: associationClub && associationClub.name,
                associationClubNumber:
                  associationClub && associationClub.club_id,
                userName: product.user.name,
                fishingCard: product.user.fishingCard,
                isForMe: product.user.isForMe ? "Tak" : "Nie",
                isForeigner: product.user.isForeigner ? "Tak" : "Nie",
                isMemberParticipant: product.user.isMemberParticipant
                  ? "Tak"
                  : "Nie",
              };
            }

            return null;
          }),
        );

        const filteredProducts = productsWithTransactionId.filter(
          (product) => product !== null,
        );
        acc.push(...(filteredProducts as Product[]));
      }

      return acc;
    },
    Promise.resolve([] as Product[]),
  );

  const processedProducts = await allProcessedProductsPromises;

  return processedProducts.map((product, i) => ({
    ...product,
    id: i,
  }));
};

import { db } from "../../firebase-config";
import { doc, DocumentReference, DocumentData } from "firebase/firestore";
import { Collection } from "./enums";

export const getFisheryReference = async (
  id: string,
): Promise<DocumentReference<DocumentData, DocumentData> | null> => {
  const getFishery = async (
    id: string,
  ): Promise<DocumentReference<DocumentData, DocumentData>> => {
    const fisheryRef = doc(db, Collection.FISHERIES, id);
    return fisheryRef;
  };
  const catchFunc = (error: unknown): null => {
    console.error(`Failed to fetch ${Collection.FISHERIES} data:`, error);
    return null;
  };

  try {
    return getFishery(id);
  } catch (error) {
    return catchFunc(error);
  }
};

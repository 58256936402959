import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { User } from "@interfaces/User";
import { Collection } from "./api/enums";

export const getUserData = async (uid: string | undefined) => {
  if (!uid) return null;

  const docRef = doc(db, Collection.USERS, uid);
  const docSnap = await getDoc(docRef);

  return docSnap.exists() ? (docSnap.data() as User) : null;
};

import { create } from "zustand";
import { DialogState } from "./interfaces";
import { DEFAULT_DIALOG_STATE } from "./consts";

export const useDialogStore = create<DialogState>((set) => ({
  ...DEFAULT_DIALOG_STATE,
  reset: () => set(DEFAULT_DIALOG_STATE),
  handleOpenDialog: ({
    title,
    content,
    confirmFunc = () => undefined,
    closeFunc = () => undefined,
    shouldCloseOnConfirm = false,
  }) =>
    set(() => ({
      title,
      content,
      confirmFunc,
      closeFunc,
      shouldCloseOnConfirm,
      open: true,
    })),
  handleCloseDialog: (shouldRunCb) =>
    set((state) => {
      if (shouldRunCb) state.closeFunc();
      return { open: false };
    }),
  handleConfirm: () =>
    set((state) => {
      state.confirmFunc();
      if (state.shouldCloseOnConfirm) {
        return { open: false, shouldCloseOnConfirm: false };
      }
      return state;
    }),
  handleSetButtonsDetails: ({ closeButtonTitle, confirmButtonTitle }) =>
    set(() => ({ closeButtonTitle, confirmButtonTitle })),
  handleResetDialogState: () => set(() => ({ ...DEFAULT_DIALOG_STATE })),
}));

import React from "react";
import {
  DateRangePicker as ReactDateRange,
  Range,
  defaultStaticRanges,
  defaultInputRanges,
  StaticRange,
  InputRange,
} from "react-date-range";
import { pl } from "date-fns/locale";
import {
  INPUT_RANGES_LABELS,
  STATIC_RANGES_LABELS,
} from "@components/CustomDatePicker/enums";
import { translateRanges } from "@components/CustomDatePicker/helpers";

import "./styles.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface CustomDatePickerProps {
  range: DateRange;
  setRange: (range: DateRange) => void;
  months?: number;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  range,
  setRange,
  months = 1,
}) => {
  const handleSelect = (ranges: { [key: string]: Range }) => {
    const selectedRange = ranges.selection;
    if (selectedRange && selectedRange.startDate && selectedRange.endDate) {
      setRange({
        startDate: selectedRange.startDate,
        endDate: selectedRange.endDate,
        key: "selection",
      });
    }
  };

  const staticRanges: StaticRange[] = defaultStaticRanges.map(
    translateRanges<StaticRange>(STATIC_RANGES_LABELS),
  );

  const inputRanges: InputRange[] = defaultInputRanges.map(
    translateRanges<InputRange>(INPUT_RANGES_LABELS),
  );

  return (
    <ReactDateRange
      className="customDateRangeFont"
      ranges={[range]}
      onChange={handleSelect}
      months={months}
      direction="horizontal"
      locale={pl}
      maxDate={new Date()}
      editableDateInputs={true}
      moveRangeOnFirstSelection={false}
      staticRanges={staticRanges}
      inputRanges={inputRanges}
    />
  );
};

export default CustomDatePicker;

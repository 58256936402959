import React, { useEffect, useState } from "react";
import Layout from "@components/Layout";
import Grid from "@mui/material/Grid";
import { DataRange } from "@interfaces/Date";
import DatePickerDialog from "@components/DatePickerDialog";
import { useTransactionsStore } from "@store/useTransactionsStore";
import { transformPeriodicPermits } from "@utils/transactions/transformPeriodicPermits";
import Box from "@mui/material/Box";
import { GridToolbar, GridValueGetterParams, plPL } from "@mui/x-data-grid";
import StyledDataGrid from "@components/StyledDataGrid";
import { DEFAULT_START_END_DATE } from "../consts/date";
import { formatAmount } from "@utils/helpers";
import { useUserStore } from "@store/useUserStore/useUserStore";

export const PeriodicPermitsPage: React.FC = () => {
  const { user } = useUserStore();
  const { products, getProducts, loading } = useTransactionsStore();

  const [selectedRange, setSelectedRange] = useState<DataRange>(
    DEFAULT_START_END_DATE,
  );

  const handleConfirmDialogDateRange = (range: DataRange) => {
    setSelectedRange(range);
  };

  useEffect(() => {
    getProducts(user, selectedRange);
  }, [selectedRange]);

  const array = transformPeriodicPermits(products);

  const columns = [
    ...array.columns,
    {
      field: "totalPrice",
      headerName: "SUMA",
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${formatAmount(params.value)} zł`;
      },
    },
  ];

  return (
    <Layout>
      <Grid container spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <DatePickerDialog onConfirm={handleConfirmDialogDateRange} />
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            sx={{ width: "100%", height: "100%", backgroundColor: "white" }}
          >
            <StyledDataGrid
              disableRowSelectionOnClick
              columnHeaderHeight={100}
              rows={array.data}
              columns={columns}
              loading={loading}
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    utf8WithBom: true,
                  },
                },
              }}
              pageSizeOptions={[15, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export const LICENCE_FEE = 17000;
export enum ProductType {
  LICENSE = "LICENSE",
  ASSOCIATION_FEE = "ASSOCIATION_FEE",
}

export enum TransactionStatus {
  DRAFT = "DRAFT",
  CREATED = "CREATED",
  PAID = "PAID",
  COMPLETED = "COMPLETED",
  EMAIL_SENT = "EMAIL_SENT",
}

export enum StatusNames {
  DRAFT = "Szkic",
  CREATED = "Utoworzona",
  PAID = "Opłacona",
  COMPLETED = "Zakończona",
  EMAIL_SENT = "Wysłano potwierdzenie",
}

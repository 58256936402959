import { LICENCE_FEE, ProductType } from "../../consts/transaction";
import {
  extractOptionName,
  generateKeyWithPrice,
  isProductAnnualLicense,
} from "@utils/helpers";
import {
  EntryFeesMap,
  FishingClubReportData,
  OptionsMap,
} from "@interfaces/FishingClubs";

export const extractProductAssociationFeeWithDiscount = (
  products: FishingClubReportData[],
  optionsMap: OptionsMap,
) =>
  products.forEach((product) => {
    if (product.type === ProductType.ASSOCIATION_FEE) {
      const isAnnual = isProductAnnualLicense(product);
      if (!isAnnual) return;

      product.discounts?.forEach(({ name }) => {
        let finalPrice = product.price;

        const discountValue = product.discounts[0].value;
        const entryFeePrice = product.price + discountValue - LICENCE_FEE;
        finalPrice -= entryFeePrice;

        const discountKey = generateKeyWithPrice(name, finalPrice);

        if (!optionsMap.has(discountKey)) {
          optionsMap.set(discountKey, { name, value: finalPrice });
        }
      });
    }
  });

export const extractProductsWithEntryFee = (
  products: FishingClubReportData[],
  entryFeesMap: EntryFeesMap,
) =>
  products.forEach((product) => {
    if (product.entryFee && product.type === ProductType.ASSOCIATION_FEE) {
      let entryFeePrice = product.price - LICENCE_FEE;
      if (product.discounts && product.discounts.length > 0) {
        const discountValue = product.discounts[0].value;
        entryFeePrice += discountValue;
      }

      entryFeesMap.set(
        generateKeyWithPrice("entryFee", entryFeePrice),
        entryFeePrice,
      );
    }
  });

export const extractProductsWithLicense = (
  products: FishingClubReportData[],
  optionsMap: OptionsMap,
) =>
  products.forEach((product) => {
    if (product.type === ProductType.LICENSE) {
      const isAnnual = isProductAnnualLicense(product);
      if (!isAnnual) return;

      if (product.licenseOption) {
        const option = extractOptionName(product.licenseOption);
        const licenseKey = generateKeyWithPrice(option, product.price);

        if (!optionsMap.has(licenseKey)) {
          optionsMap.set(licenseKey, {
            name: option,
            value: product.price,
            discount: product.discounts[0]?.name,
          });
        }
      }
    }
  });

import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { useDialogStore } from "@store/useDialogStore/useDialogStore";
import CloseIcon from "@mui/icons-material/Close";

const DialogComponent: React.FC = () => {
  const {
    open,
    handleCloseDialog,
    handleConfirm,
    title,
    content,
    closeButtonTitle,
    confirmButtonTitle,
  } = useDialogStore();
  return (
    <Dialog
      open={open}
      onClose={() => handleCloseDialog(false)}
      fullWidth={true}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title}{" "}
        <CloseIcon
          onClick={() => handleCloseDialog(false)}
          style={{ cursor: "pointer" }}
        />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {closeButtonTitle && (
          <Button color="error" onClick={() => handleCloseDialog(true)}>
            {closeButtonTitle}
          </Button>
        )}
        {confirmButtonTitle && (
          <Button onClick={handleConfirm}>{confirmButtonTitle}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;

export const DEFAULT_DIALOG_STATE = {
  open: false,
  shouldCloseOnConfirm: false,
  confirmFunc: () => undefined,
  closeFunc: () => undefined,
  title: "",
  content: "",
  closeButtonTitle: "Zamknij",
  confirmButtonTitle: "Zatwierdź",
};

import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid, GridColDef, GridToolbar, plPL } from "@mui/x-data-grid";
import { useUserStore } from "@store/useUserStore/useUserStore";
import { useRangerStore } from "@store/useRangerStore";
import { parseTimestamp } from "@utils/helpers";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { ROLES } from "@store/useUserStore/enums";

export const RangerReportPage = () => {
  const { loading: dataLoading, getControls, controls } = useRangerStore();
  const { user: currentUser, hasRole } = useUserStore();
  const associationIsVisible = hasRole([ROLES.SUPERADMIN]);

  useEffect(() => {
    getControls(currentUser.association_name ?? "");
  }, [currentUser.uid]);

  const columns: GridColDef[] = [
    {
      field: "control_date",
      headerName: "Data kontroli",
      width: 180,
      renderCell: (params) =>
        parseTimestamp(params.row.control_date, "dd/MM/yyyy HH:mm"),
      flex: 0.2,
    },
    {
      field: "association_name",
      headerName: "Okrąg PZW",
      flex: 0.4,
    },
    {
      field: "controller_email",
      headerName: "Email kontrolującego",
      flex: 0.2,
    },
    {
      field: "controller_name",
      headerName: "Imię kontrolującego",
      flex: 0.2,
    },
    {
      field: "user_email",
      headerName: "Email kontrolowanego",
      flex: 0.2,
    },
    {
      field: "user_name",
      headerName: "Imię kontrolowanego",
      flex: 0.2,
    },
    {
      field: "association_card",
      headerName: "Legitymacja członkowska",
      flex: 0.2,
    },
    {
      field: "is_success",
      headerName: "Status Kontroli",
      flex: 0.2,
      renderCell: (params) =>
        params.row.is_success ? (
          <CheckCircleOutlineIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        ),
    },
    {
      field: "licence",
      headerName: "Czy było zezwolenie",
      flex: 0.2,
      renderCell: (params) => {
        return params.row.licence ? "Tak" : "Nie";
      },
    },
    {
      field: "association_fee",
      headerName: "Czy była składka członkowska",
      flex: 0.2,
      renderCell: (params) => {
        return params.row.association_fee ? "Tak" : "Nie";
      },
    },
    {
      field: "association_fee.entry_fee",
      headerName: "Czy było wpisowe",
      flex: 0.2,
      renderCell: (params) => {
        if (!params.row.association_fee) return "Brak Info";
        return params.row.association_fee.entry_fee ? "Tak" : "Nie";
      },
    },
    {
      field: "rejection_reason",
      headerName: "Uwagi",
      flex: 0.5,
      renderCell: (params) => {
        if (!params.row.rejection_reason) return "";
        return (
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {params.row.rejection_reason.map((reason: string) => (
              <li>{reason}</li>
            ))}
          </ul>
        );
      },
    },
  ];

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: "100%",
              height: 760,
              backgroundColor: "white",
            }}
          >
            <DataGrid
              disableRowSelectionOnClick
              rows={controls}
              loading={dataLoading}
              columns={
                associationIsVisible
                  ? columns
                  : columns.filter(
                      (column) => column.field !== "association_name",
                    )
              }
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    utf8WithBom: true,
                  },
                },
              }}
              pageSizeOptions={[15, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export const tryCatchFunc = async <T, K>(
  tryFunc: () => T,
  catchFunc: (error: unknown) => K,
  finallyFunc: () => void,
): Promise<T | K> => {
  try {
    return tryFunc();
  } catch (error: unknown) {
    return catchFunc(error);
  } finally {
    finallyFunc();
  }
};

import { ROLES } from "@store/useUserStore/enums";

export const DEFAULT_ROLES = [ROLES.USER];
export const DEFAULT_VERSION = "1.0.0";

export const DEFAULT_USERS_STATE = {
  loading: false,
  user: {
    uid: "",
    email: "",
    roles: DEFAULT_ROLES,
    display_name: "",
    association_id: null,
    association_name: "",
    association_card: "",
    association_club_id: null,
    association_club_name: "",
    favorite_fishery: null,
    favorite_fishing_method: null,
    fishing_card: "",
    created_time: null,
    isForeigner: false,
    app_version: DEFAULT_VERSION,
    has_app_version: false,
    photo_url: null,
  },
  users: [],
};

import React, { useEffect, useState } from "react";
import { Fishing } from "@interfaces/Fishing";
import { DataRange } from "@interfaces/Date";
import { FishData } from "@interfaces/Charts";
import { transformDataBarChartDivisionFish } from "@utils/charts";
import Loading from "@components/Loading";
import { CardComponent } from "@components/Card";
import { areElementsInArray } from "@utils/arrayUtils";
import { Box, Chip } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

interface BarChartDivisionFishProps {
  loading: boolean;
  data: Fishing[];
  selectedRange: DataRange;
}

export const BarChartDivisionFish: React.FC<BarChartDivisionFishProps> = ({
  loading,
  data,
  selectedRange,
}) => {
  const [barChartData, setBarChartData] = useState<FishData[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    if (!loading) {
      const transformedData: FishData[] = transformDataBarChartDivisionFish(
        data,
        selectedRange,
      );
      setBarChartData(transformedData);
      setIsLoading(false);
    }
  }, [loading, data, selectedRange]);

  const releasedCountFish: number[] = (barChartData || []).map(
    (item: FishData) => item.releasedCount,
  );
  const keptCountFish: number[] = (barChartData || []).map(
    (item: FishData) => item.keptCount,
  );
  const numberFishCaughtData: string[] = (barChartData || []).map(
    (item: FishData) => item.date,
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CardComponent title="Ilość złowionych ryb z podziałem">
      {!areElementsInArray(releasedCountFish) ||
      !areElementsInArray(keptCountFish) ||
      !areElementsInArray(numberFishCaughtData) ? (
        <Box display="flex" sx={{ width: "100%", justifyContent: "center" }}>
          <Chip
            label="Brak danych w wybranym zakresie"
            color="info"
            variant="outlined"
          />
        </Box>
      ) : (
        <BarChart
          xAxis={[{ scaleType: "band", data: [...numberFishCaughtData] }]}
          series={[
            {
              data: [...releasedCountFish],
              stack: "A",
              label: "Wypuszczona ryba",
            },
            {
              data: [...keptCountFish],
              stack: "A",
              label: "Zatrzymana ryba",
            },
          ]}
          height={300}
        />
      )}
    </CardComponent>
  );
};

import Snackbar from "@mui/material/Snackbar";
import { useSnackbarStore } from "@store/useSnackbarStore/useSnackbarStore";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import "./styles.scss";

const SnackbarComponent = () => {
  const {
    handleCloseSnackbar,
    message,
    autoHideDuration,
    isSnackbarVisible,
    anchorOrigin,
    variant,
  } = useSnackbarStore();

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={isSnackbarVisible}
      autoHideDuration={autoHideDuration}
      onClose={handleCloseSnackbar}
      className={`snackbar snackbar-${variant}`}
      message={
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          justifyContent="space-between"
        >
          {message}{" "}
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={handleCloseSnackbar}
          />
        </Box>
      }
    />
  );
};

export default SnackbarComponent;

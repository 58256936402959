import { db } from "../../firebase-config";
import { updateDoc, doc } from "firebase/firestore";
import { tryCatchFunc } from "./helpers";
import { Collection, ResponseStatus } from "./enums";
import { Fishery } from "../../interfaces/Fishery";

export const editFisheryData = async (
  fishery: Fishery,
  finnalyFunc: () => void = () => undefined,
): Promise<ResponseStatus> => {
  const editFishery = async (fishery: Fishery): Promise<ResponseStatus> => {
    const docRef = doc(db, "fisheries", fishery.id);
    const data = {
      ...fishery,
      location: fishery.location.map((location) => Object.assign({}, location)),
    };
    return await updateDoc(docRef, data).then(() => ResponseStatus.SUCCESS);
  };
  const catchFunc = (error: unknown): ResponseStatus => {
    console.error(
      `Failed to add new fishery to ${Collection.FISHERIES} collection. Error:`,
      error,
    );
    return ResponseStatus.FAIL;
  };

  return tryCatchFunc(() => editFishery(fishery), catchFunc, finnalyFunc);
};

import { MapContainer, TileLayer, useMap, useMapEvents } from "react-leaflet";
import { LatLng, LatLngBoundsExpression } from "leaflet";
import { getFirstLocation } from "@utils/geolocation";
import {
  getPolygonComponent,
  getPopUpComponent,
  getMarkerComponent,
  getLocationComponent,
} from "./helpers";
import { FisheryMapMode } from "../../store/useFisheriesStore/enums";
import { useFisheriesStore } from "../../store/useFisheriesStore";
import { areElementsInArray } from "@utils/arrayUtils";
import { useState } from "react";
import { DEFAULT_CENTER } from "@store/useFisheriesStore/consts";

interface ChangeViewProps {
  handleSetNewFisheryPoint: (newPoint: LatLng) => void;
  mapMode: FisheryMapMode;
  handleSetNewEditFisheryPoint: (newPoint: LatLng) => void;
  bounds: LatLngBoundsExpression | undefined;
  setMapBounds: (center: [number, number][] | undefined) => void;
  handleSetSelectedBoundsByUser: (
    bounds: [number, number][] | undefined,
  ) => void;
}

const FisheriesMap = () => {
  const {
    zoom,
    selectedBoundsByUser,
    mapMode,
    handleSetNewFisheryPoint,
    editFishery,
    newFishery,
    handleSetNewEditFisheryPoint,
    visibleFisheries,
    handleSetSelectedBoundsByUser,
  } = useFisheriesStore();

  const [mapBounds, setMapBounds] = useState<[number, number][] | undefined>();

  const renderAssets = () => {
    const currentFisheries = visibleFisheries
      .filter((fishery) => fishery.id !== editFishery?.id)
      .map((fishery) => {
        if (!fishery.location || fishery.location.length === 0) return null;
        const location = getFirstLocation(fishery.location);
        const PopupComponent = getPopUpComponent(location, fishery.name);
        if (fishery.location.length === 1) {
          return (
            <div key={fishery.id}>
              {getMarkerComponent(PopupComponent, location)}
            </div>
          );
        }
        return (
          <div key={fishery.id}>
            {getPolygonComponent(
              PopupComponent,
              fishery.location.map(({ lat, lng }) => [lat, lng]),
            )}
          </div>
        );
      });

    const newFisheryLocation = getLocationComponent(
      newFishery,
      mapMode === FisheryMapMode.ADD_NEW_FISHERY,
    );

    const editFisheryLocation = getLocationComponent(
      editFishery,
      mapMode === FisheryMapMode.EDIT_FISHERY,
    );

    return (
      <>
        {currentFisheries}
        {newFisheryLocation}
        {editFisheryLocation}
      </>
    );
  };

  const getBounds = (): [number, number][] | undefined => {
    if (newFishery && areElementsInArray(newFishery.location)) {
      return newFishery.location.map((location) => [
        location.lat,
        location.lng,
      ]);
    }
    if (editFishery && areElementsInArray(editFishery.location)) {
      return editFishery.location.map((location) => [
        location.lat,
        location.lng,
      ]);
    }
    if (selectedBoundsByUser) {
      return selectedBoundsByUser;
    }
    if (mapBounds) {
      return mapBounds;
    }
  };

  return (
    <MapContainer
      center={new LatLng(DEFAULT_CENTER.lat, DEFAULT_CENTER.lng)}
      zoom={zoom}
      scrollWheelZoom={true}
      style={{ height: "100%", width: "100%", position: "relative" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ChangeView
        handleSetNewFisheryPoint={handleSetNewFisheryPoint}
        mapMode={mapMode}
        handleSetNewEditFisheryPoint={handleSetNewEditFisheryPoint}
        bounds={getBounds()}
        setMapBounds={setMapBounds}
        handleSetSelectedBoundsByUser={handleSetSelectedBoundsByUser}
      />
      {renderAssets()}
    </MapContainer>
  );
};
const ChangeView = ({
  handleSetNewFisheryPoint,
  handleSetNewEditFisheryPoint,
  mapMode,
  bounds,
  handleSetSelectedBoundsByUser,
  setMapBounds,
}: ChangeViewProps) => {
  const map = useMap();
  useMapEvents({
    dragend: (event) => {
      console.log(event, "event");
      handleSetSelectedBoundsByUser(undefined);
      setMapBounds(event.target.getBounds());
    },
    click: (event) => {
      if (mapMode === FisheryMapMode.ADD_NEW_FISHERY) {
        handleSetNewFisheryPoint(event.latlng);
      }
      if (mapMode === FisheryMapMode.EDIT_FISHERY) {
        handleSetNewEditFisheryPoint(event.latlng);
      }
    },
  });
  if (
    [
      FisheryMapMode.EDIT_FISHERY,
      FisheryMapMode.ADD_NEW_FISHERY,
      FisheryMapMode.NEW_FISHERY,
    ].includes(mapMode)
  ) {
    return null;
  }
  if (bounds) {
    map.fitBounds(bounds);
    return null;
  }
  map.closePopup();
  return null;
};
export default FisheriesMap;

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ListMode } from "../enums";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { areElementsInArray } from "@utils/arrayUtils";
import { FisheryMapMode } from "@store/useFisheriesStore/enums";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFisheriesStore } from "@store/useFisheriesStore";
import { useSnackbarStore } from "@store/useSnackbarStore/useSnackbarStore";
import { SnackbarVariant } from "@store/useSnackbarStore/enums";
import { useDialogStore } from "@store/useDialogStore/useDialogStore";

type Props = {
  handleUpdateListView: (listMode: ListMode) => void;
};

const AddFishery = ({ handleUpdateListView }: Props) => {
  const {
    handleSetMapMode,
    mapMode,
    newFishery,
    isFisheryEdited,
    handleResetNewFishery,
    handleSetNewFisheryName,
    handleDeleteCoordinateFromNewFishery,
    handleSaveNewFishery,
    handleSetFisheryEdited,
  } = useFisheriesStore();

  const { handleOpenSnackbar } = useSnackbarStore();

  const { handleOpenDialog, handleSetButtonsDetails, handleResetDialogState } =
    useDialogStore();

  const goBack = () => {
    handleResetNewFishery();
    handleSetMapMode(FisheryMapMode.STATIC);
    handleUpdateListView(ListMode.LIST);
    handleSetFisheryEdited(false);
    handleResetDialogState();
  };

  return (
    <Box height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ArrowBackIcon
          onClick={() => {
            if (isFisheryEdited) {
              handleOpenDialog({
                title: "Czy na pewno chcesz opuścić ten ekran?",
                content: (
                  <Typography variant="body1">
                    Twoje zmiany łowiska nie zostaną zapisane
                  </Typography>
                ),
                shouldCloseOnConfirm: true,
                closeFunc: goBack,
              });
              handleSetButtonsDetails({
                closeButtonTitle: "Opuść widok",
                confirmButtonTitle: "Zostań na tym widoku",
              });
              return;
            }
            goBack();
          }}
          style={{ minWidth: 0, cursor: "pointer" }}
        />
        <Button
          disabled={
            !(
              newFishery.name &&
              newFishery.location &&
              newFishery.location.length
            )
          }
          onClick={() =>
            handleSaveNewFishery(newFishery, () => {
              handleOpenSnackbar({
                message: "Zapisano zmiany",
                variant: SnackbarVariant.SUCCESS,
              }),
                handleUpdateListView(ListMode.EDIT_FISHERY);
            })
          }
        >
          Zapisz nowe łowisko
        </Button>
      </Box>
      <Box marginTop="16px">
        <TextField
          placeholder="Nazwa łowiska"
          variant="outlined"
          fullWidth
          onChange={(event) => handleSetNewFisheryName(event.target.value)}
          value={newFishery.name}
        />
      </Box>
      <Box marginTop="16px">
        {mapMode === FisheryMapMode.NEW_FISHERY && (
          <Button
            onClick={() => handleSetMapMode(FisheryMapMode.ADD_NEW_FISHERY)}
          >
            Zaznacz obszar na mapie
          </Button>
        )}
        {mapMode === FisheryMapMode.ADD_NEW_FISHERY && (
          <Button
            color="error"
            onClick={() => {
              handleSetMapMode(FisheryMapMode.NEW_FISHERY);
            }}
          >
            Zakończ
          </Button>
        )}
      </Box>
      <Box marginTop="16px" overflow="auto" maxHeight="calc(100% - 170px)">
        <Typography variant="h5">Koordynaty:</Typography>
        {areElementsInArray(newFishery.location) ? (
          newFishery.location?.map((location, index) => (
            <Box marginTop="8px">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography fontWeight={700}>
                    Koordynat numer {index + 1}:
                  </Typography>
                </Box>
                {mapMode === FisheryMapMode.ADD_NEW_FISHERY && (
                  <DeleteIcon
                    onClick={() => handleDeleteCoordinateFromNewFishery(index)}
                    color="error"
                    style={{ minWidth: 0, cursor: "pointer" }}
                  />
                )}
              </Box>
              <Typography>szerokość: {location.lat}</Typography>
              <Typography>długość: {location.lng}</Typography>
            </Box>
          ))
        ) : (
          <Typography marginTop="16px">
            Nie dodałeś jeszcze żadnych koordynatów.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AddFishery;

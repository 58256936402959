import React from "react";
import Box from "@mui/material/Box";
import { FishingClubReportData, Option } from "@interfaces/FishingClubs";
import { formatAmount, generateKeyWithPrice } from "../helpers";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { extractProductsWithLicense } from "@utils/fishingClubs/helpers";
import { Typography } from "@mui/material";

export const extractLicenseColumns = (products: FishingClubReportData[]) => {
  const optionsMap: Map<string, Option> = new Map();
  extractProductsWithLicense(products, optionsMap);

  return Array.from(optionsMap.values()).map(
    (license): GridColDef => ({
      field: generateKeyWithPrice(license.name, license.value),
      headerName: `Zezwolenie: ${license.name} ${license.discount && `z ulgą: ${license.discount}`} (${formatAmount(license.value)} zł)`,
      minWidth: 160,
      width: license.discount
        ? license.discount.length * 9
        : license.name.length * 9,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.licenseOptionsCount?.[
          generateKeyWithPrice(license.name, license.value)
        ];
      },
      renderHeader: () => (
        <Box
          sx={{ display: "flex", flexDirection: "column", lineHeight: "1.4" }}
        >
          {license.discount && (
            <Typography variant="caption">Ulga: {license.discount}</Typography>
          )}
          <strong>Zezwolenie</strong>
          <Typography variant="caption">- {license.name}</Typography>
          <span>({formatAmount(license.value)} zł)</span>
        </Box>
      ),
    }),
  );
};

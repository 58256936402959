import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

const StyledDataGrid = styled(DataGrid)(() => {
  return {
    border: 0,
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#fafafa",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid #f0f0f0}`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid #f0f0f0`,
    },
    "& .MuiDataGrid-cell": {
      color: "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
  };
});

export default StyledDataGrid;

import { create } from "zustand";
import { DEFAULT_SNACKBAR_STATE } from "./consts";
import { OpenParams, SnackbarState } from "./interfaces";

export const useSnackbarStore = create<SnackbarState>((set) => ({
  ...DEFAULT_SNACKBAR_STATE,

  reset: () => set(DEFAULT_SNACKBAR_STATE),
  handleOpenSnackbar: ({ message, anchorOrigin, variant }: OpenParams) =>
    set((state: SnackbarState) => ({
      ...state,
      isSnackbarVisible: true,
      message,
      anchorOrigin,
      variant,
    })),
  handleCloseSnackbar: () => set(() => ({ isSnackbarVisible: false })),
}));

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import { Association } from "@interfaces/Association";
import { tryCatchFunc } from "@utils/api/helpers";

export const getAssociations = async (
  finallyFunc: () => void = () => undefined,
): Promise<Association[]> => {
  const getFishing = async (): Promise<Association[]> => {
    const associationsCollection = collection(db, "associations");
    const associationsSnapshot = await getDocs(associationsCollection);
    return associationsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as unknown as Association[];
  };
  const catchFunc = (error: unknown): [] => {
    console.error("Failed to fetch association data:", error);
    return [];
  };

  return tryCatchFunc(getFishing, catchFunc, finallyFunc);
};

export const DEFAULT_TRANSACTIONS_STATE = {
  loading: false,
  transactions: [],
  products: [],
  fishingClubs: {
    entryFeeColumns: [],
    groupedProducts: [],
    licenseColumns: [],
    licenseWithDiscountColumns: [],
  },
  transactionsGroup: [],
};

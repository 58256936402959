import { Fishing } from "@interfaces/Fishing";
import { DataRange } from "@interfaces/FishingClubs";
import { User } from "@interfaces/User";

import { ROLES } from "@store/useUserStore/enums";

import {
  collection,
  doc,
  getDocs,
  Query,
  query,
  where,
  orderBy,
} from "firebase/firestore";

import { startOfDay, endOfDay, subMonths } from "date-fns";

import { db } from "../../firebase-config";

import { Collection } from "./enums";
import { tryCatchFunc } from "./helpers";

const MaxUsersChunkSize = 30;

export const getFishingData = async (
  user: User,
  users: User[] | null,
  selectedRange?: DataRange | null,
  finallyFunc: () => void = () => undefined,
): Promise<Fishing[]> => {
  if (!user.uid) {
    return [];
  }

  const getFishing = async (): Promise<Fishing[]> => {
    // A non-empty array is required for 'in' filters
    if (users && users.length === 0) return [];

    // limit for 30 users in 'in' filter
    if (users !== null && users.length > 30) {
      const usersChunks = [];
      for (let i = 0; i < users.length; i += MaxUsersChunkSize) {
        usersChunks.push(users.slice(i, i + MaxUsersChunkSize));
      }

      const promises = usersChunks.map((chunk) => {
        const query = fishingQuery(user, chunk, selectedRange);
        return getDocs(query);
      });

      const querySnapshots = await Promise.all(promises);

      return querySnapshots.flatMap((querySnapshot) =>
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
        })),
      ) as Fishing[];
    } else {
      // for dashboard view - all data, no 'in' filter
      const query = fishingQuery(user, users, selectedRange);
      const querySnapshot = await getDocs(query);

      return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      })) as Fishing[];
    }
  };
  const catchFunc = (error: unknown): [] => {
    console.error("Failed to fetch fishing data:", error);
    return [];
  };

  return tryCatchFunc(getFishing, catchFunc, finallyFunc);
};

const fishingQuery = (
  user: User,
  users: User[] | null,
  selectedRange?: DataRange | null,
): Query => {
  let q = query(collection(db, Collection.FISHINGS));

  // data access
  if (user.roles.includes(ROLES.SUPERADMIN)) {
    // all data
    q = query(q);
  } else if (user.roles.includes(ROLES.ADMIN)) {
    // association data only
    q = query(q, where("association_id", "==", user.association_id));
  } else {
    // user data only
    const userDocRef = doc(db, Collection.USERS, user.uid);
    q = query(q, where("user", "==", userDocRef));
  }

  // data range
  const startDate = selectedRange
    ? selectedRange.startDate
    : subMonths(new Date(), 12);
  const endDate = selectedRange ? selectedRange.endDate : new Date();

  if (selectedRange) {
    q = query(
      q,
      where("start_date", ">=", startOfDay(startDate)),
      where("end_date", "<=", endOfDay(endDate)),
    );
  }

  // const userDocRef = doc(db, Collection.USERS, user.uid);

  if (users !== null) {
    const list = users?.map((user) => doc(db, Collection.USERS, user.uid));
    q = query(q, where("user", "in", list));
  }

  // sorting
  q = query(q, orderBy("start_date", "desc"));

  return q;
};

import { DataRange } from "@interfaces/FishingClubs";
import { User } from "@interfaces/User";

import { getLicenceData } from "@utils/api/licences/getLicencesData";

import { create } from "zustand";

import { DEFAULT_LICENCES_STATE } from "./consts";
import { LicencesState } from "./interfaces";

export const useLicencesStore = create<LicencesState>((set) => ({
  ...DEFAULT_LICENCES_STATE,

  reset: () => set(DEFAULT_LICENCES_STATE),
  setLoading: (loading) => set(() => ({ loading })),
  getLicences: async (user: User, selectedRange?: DataRange) => {
    set({ loading: true });
    const rawLicences = await getLicenceData(user, selectedRange);
    if (rawLicences && rawLicences.length) {
      set({ licences: rawLicences, loading: false });
    } else {
      set({ licences: [], loading: false });
    }
  },
}));

export enum PositionVertical {
  BOTTOM = "bottom",
  TOP = "top",
}

export enum PositionHorizontal {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export enum SnackbarVariant {
  DEFAULT = "default",
  SUCCESS = "success",
}

import { User } from "@interfaces/User";

import { Query, collection, getDocs, query, where } from "firebase/firestore";

import { tryCatchFunc } from "../helpers";
import { db } from "../../../firebase-config";
import { DataRange } from "@interfaces/FishingClubs";
import { Collection } from "../enums";
import { ROLES } from "@store/useUserStore/enums";
import { endOfDay, startOfDay, subMonths } from "date-fns";

export const getUsersData = async (
  user: User,
  selectedRange: DataRange,
  finallyFunc: () => void = () => undefined,
): Promise<User[]> => {
  const getUsers = async (): Promise<User[]> => {
    const query = userQuery(user, selectedRange);
    const usersSnapshot = await getDocs(query);
    return usersSnapshot.docs.map((doc) => ({
      ...doc.data(),
    })) as User[];
  };
  const catchFunc = (error: unknown): [] => {
    console.error("Failed to fetch user data:", error);
    return [];
  };

  return tryCatchFunc(getUsers, catchFunc, finallyFunc);
};

const userQuery = (user: User, selectedRange?: DataRange): Query => {
  let q = query(collection(db, Collection.USERS));

  // data access
  if (user.roles.includes(ROLES.SUPERADMIN)) {
    // all data
    q = query(q);
  } else if (user.roles.includes(ROLES.ADMIN)) {
    // association data only
    q = query(q, where("association_id", "==", user.association_id));
  } else {
    // user data only
    q = query(q, where("uid", "==", user.uid));
  }

  // data range
  const startDate = selectedRange
    ? selectedRange.startDate
    : subMonths(new Date(), 12);
  const endDate = selectedRange ? selectedRange.endDate : new Date();

  q = query(
    q,
    where("created_time", ">=", startOfDay(startDate)),
    where("created_time", "<=", endOfDay(endDate)),
  );

  return q;
};

import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import { AssociationClub } from "@interfaces/AssociationClub";

interface AssociationClubData {
  associationClubs: AssociationClub[];
  loading: boolean;
}

const useAssociationClub = (): AssociationClubData => {
  const [associationClubs, setAssociationClubs] = useState<AssociationClub[]>(
    [],
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAssociationClub = async () => {
      try {
        const associationClubCol = collection(db, "association_clubs");
        const associationClubSnapshot = await getDocs(associationClubCol);
        const associationClubList: AssociationClub[] =
          associationClubSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          })) as unknown as AssociationClub[];
        setAssociationClubs(associationClubList);
      } catch (error) {
        console.error("Failed to fetch association club data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssociationClub().catch(console.error);
  }, []);

  return { associationClubs, loading };
};

export default useAssociationClub;

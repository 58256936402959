import { Licence } from "@interfaces/Licences";

export const transformLicences = (licences: Licence[]) => {
  return licences.map(
    (item: Licence, i: number): Licence => ({
      id: i,
      created_date: item.created_date || "",
      start_date: item.start_date || "",
      end_date: item.end_date || "",
      option: item?.option,
      association_name: item?.association_name,
      licence_id: item?.licence_id,
      transaction: item.transaction,
      discounts: [],
      userName: item?.user?.name,
      fishingCard: item?.user?.fishing_card,
      isForMe: item?.user?.for_me ? "Tak" : "Nie",
      isForeigner: item?.user?.foreigner ? "Tak" : "Nie",
    }),
  );
};

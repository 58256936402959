import { green, blue } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    primary: {
      main: green[800],
    },
    secondary: {
      main: blue[500],
    },
  },
});

export default theme;

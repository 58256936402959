import { Fishing } from "@interfaces/Fishing";
import { startOfDay, endOfDay, format } from "date-fns";
import { DataRange } from "@interfaces/Date";

interface FishData {
  date: string;
  releasedCount: number;
  keptCount: number;
}

export const transformDataBarChartDivisionFish = (
  data: Fishing[],
  selectedRange: DataRange,
): FishData[] => {
  const adjustedStartDate = startOfDay(selectedRange.startDate);
  const adjustedEndDate = endOfDay(selectedRange.endDate);

  const dailyCounts: Record<
    string,
    { releasedCount: number; keptCount: number }
  > = {};
  const dateToTimestamp: Record<string, number> = {};

  data.forEach((fishing) => {
    fishing.fishes?.forEach((fish) => {
      if (fish.create_date !== undefined) {
        const fishDate = new Date(fish.create_date.seconds * 1000);
        if (fishDate >= adjustedStartDate && fishDate <= adjustedEndDate) {
          const dateString = format(fishDate, "dd-MM-yyyy");
          dailyCounts[dateString] = dailyCounts[dateString] || {
            releasedCount: 0,
            keptCount: 0,
          };
          dailyCounts[dateString].releasedCount += fish.released
            ? fish.count || 0
            : 0;
          dailyCounts[dateString].keptCount += fish.released
            ? 0
            : fish.count || 0;
          dateToTimestamp[dateString] = fishDate.getTime();
        }
      }
    });
  });

  const sortedDates = Object.keys(dailyCounts).sort(
    (a, b) => dateToTimestamp[a] - dateToTimestamp[b],
  );

  return sortedDates.map((date) => ({
    date,
    releasedCount: dailyCounts[date].releasedCount,
    keptCount: dailyCounts[date].keptCount,
  }));
};

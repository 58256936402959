import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import PhishingIcon from "@mui/icons-material/Phishing";
import GridViewIcon from "@mui/icons-material/GridView";
import BusinessIcon from "@mui/icons-material/Business";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PaymentsIcon from "@mui/icons-material/Payments";
import BadgeIcon from "@mui/icons-material/Badge";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import SettingsIcon from "@mui/icons-material/Settings";
import SailingIcon from "@mui/icons-material/Sailing";
import PeopleIcon from "@mui/icons-material/People";
import SummarizeIcon from "@mui/icons-material/Summarize";

import { route } from "../../consts/routes";
import { useNavigate } from "react-router-dom";
import { isLocalHost } from "@utils/helpers";
import { ROLES } from "@store/useUserStore/enums";
import { useUserStore } from "@store/useUserStore/useUserStore";
import ListItem from "@components/ListItem";

import "./styles.scss";

type NavigationProps = { drawerWidth: number };

const Navigation: React.FC<NavigationProps> = ({ drawerWidth }) => {
  const navigate = useNavigate();
  const { hasRole } = useUserStore();

  return (
    <Drawer
      open
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
        },
      }}
    >
      <div className="nav--logo">
        <img src="logo.png" alt="" />
      </div>
      <Box sx={{ overflow: "auto" }}>
        <List
          subheader={<ListSubheader component="div">DASHBOARD</ListSubheader>}
        >
          <ListItem
            to={route.HOME}
            onClick={() => navigate(route.HOME)}
            icon={<GridViewIcon />}
            label="Przegląd"
          />
        </List>
        <Divider />
        <List subheader={<ListSubheader component="div">POŁOWY</ListSubheader>}>
          <ListItem
            to={route.FISHING}
            onClick={() => navigate(route.FISHING)}
            icon={<PhishingIcon />}
            label="Historia"
          />
          <ListItem
            to={route.FISHERIES}
            onClick={() => navigate(route.FISHERIES)}
            icon={<SailingIcon />}
            label="Łowiska"
          />
        </List>
        <List
          subheader={
            <ListSubheader component="div">SKŁADKI I ZEZWOLENIA</ListSubheader>
          }
        >
          <ListItem
            to={route.LICENCES}
            onClick={() => navigate(route.LICENCES)}
            icon={<BadgeIcon />}
            label="Wszystkie zezwolenia"
          />
          <ListItem
            to={route.TRANSACTIONS}
            onClick={() => navigate(route.TRANSACTIONS)}
            icon={<PaymentsIcon />}
            label="Transakcje"
          />
          {hasRole([ROLES.SUPERADMIN, ROLES.ADMIN]) && (
            <>
              <ListItem
                to={route.FISHING_CLUBS}
                onClick={() => navigate(route.FISHING_CLUBS)}
                icon={<GroupWorkIcon />}
                label="Zezwolenia - Koła PZW"
              />
              <ListItem
                to={route.PERIODIC_PERMITS}
                onClick={() => navigate(route.PERIODIC_PERMITS)}
                icon={<NewspaperIcon />}
                label="Zezwolenia - okresowe"
              />
            </>
          )}
        </List>

        {hasRole([ROLES.SUPERADMIN, ROLES.ADMIN]) && (
          <List
            subheader={<ListSubheader component="div">RAPORTY</ListSubheader>}
          >
            <>
              <ListItem
                to={route.USERS_SUMMARY}
                onClick={() => navigate(route.USERS_SUMMARY)}
                icon={<BusinessIcon />}
                label="Okręgi PZW"
              />
              <ListItem
                to={route.USERS}
                onClick={() => navigate(route.USERS)}
                icon={<PeopleIcon />}
                label="Użytkownicy"
              />
              <ListItem
                to={route.RANGER_REPORT}
                onClick={() => navigate(route.RANGER_REPORT)}
                icon={<SummarizeIcon />}
                label="Historia kontroli"
              />
            </>
          </List>
        )}

        {hasRole([ROLES.SUPERADMIN]) && isLocalHost() && (
          <>
            <Divider />
            <List
              subheader={
                <ListSubheader component="div">ADMINISTRACJA</ListSubheader>
              }
            >
              <ListItem
                to={route.MAINTENANCE}
                onClick={() => navigate(route.MAINTENANCE)}
                icon={<SettingsIcon />}
                label="Baza danych"
              />
            </List>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default Navigation;

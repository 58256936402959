import React, { useEffect, useState } from "react";
import Layout from "@components/Layout";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  GridToolbar,
  plPL,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { DataRange } from "@interfaces/FishingClubs";
import { DEFAULT_START_END_DATE } from "../consts/date";
import DatePickerDialog from "@components/DatePickerDialog";
import StyledDataGrid from "@components/StyledDataGrid";
import { useLicencesStore } from "@store/useLicencesStore";
import { transformLicences } from "@utils/licences/transformLicences";
import { parseTimestamp } from "@utils/helpers";
import { useUserStore } from "@store/useUserStore/useUserStore";

export const LicencesPage: React.FC = () => {
  const [selectedRange, setSelectedRange] = useState<DataRange>(
    DEFAULT_START_END_DATE,
  );
  const { loading, licences, getLicences } = useLicencesStore();
  const { user } = useUserStore();

  const handleConfirmDialogDateRange = (range: DataRange) => {
    setSelectedRange(range);
  };

  useEffect(() => {
    getLicences(user, selectedRange);
  }, [user, selectedRange]);

  const rows = transformLicences(licences);

  const columns: GridColDef[] = [
    {
      field: "transaction",
      headerName: "ID transakcji",
      width: 300,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.transaction?.id ?? "",
      renderCell: (params) => params.row.transaction?.id ?? "",
    },
    {
      field: "licence_id",
      headerName: "Numer licencji",
      width: 150,
    },
    {
      field: "created_date",
      headerName: "Data transakcji",
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        parseTimestamp(params.value, "yyyy-MM-dd HH:mm"),
      renderCell: (params) =>
        parseTimestamp(params.row.created_date, "dd/MM/yyyy HH:mm"),
    },
    {
      field: "start_date",
      headerName: "Początek okresu",
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        parseTimestamp(params.value, "yyyy-MM-dd HH:mm"),
      renderCell: (params) =>
        parseTimestamp(params.row.start_date, "dd/MM/yyyy HH:mm"),
    },
    {
      field: "end_date",
      headerName: "Koniec okresu",
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        parseTimestamp(params.value, "yyyy-MM-dd HH:mm"),
      renderCell: (params) =>
        parseTimestamp(params.row.end_date, "dd/MM/yyyy HH:mm"),
    },
    {
      field: "option",
      headerName: "Zezwolenie - rodzaj",
      width: 250,
    },
    {
      field: "association_name",
      headerName: "Okręg",
      width: 300,
    },
    {
      field: "userName",
      headerName: "Imię i nazwisko",
      width: 220,
    },
    {
      field: "fishingCard",
      headerName: "Karta wędkarska",
      width: 150,
    },
    {
      field: "isForMe",
      headerName: "Czy dla siebie",
      width: 130,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          sx={{ "& .MuiChip-label": { lineHeight: 1 } }}
          label={params.row.isForMe}
          variant="outlined"
          size="small"
          color={params.row.isForMe === "Tak" ? "success" : "error"}
        />
      ),
    },
    {
      field: "isForeigner",
      headerName: "Czy obcokrajowiec",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          sx={{ "& .MuiChip-label": { lineHeight: 1 } }}
          label={params.row.isForeigner}
          variant="outlined"
          size="small"
          color={params.row.isForeigner === "Tak" ? "success" : "error"}
        />
      ),
    },
  ];

  return (
    <Layout>
      <Grid container spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <DatePickerDialog onConfirm={handleConfirmDialogDateRange} />
        </Grid>
        <Grid item xs={12} sx={{ height: "calc(100% - 70px)" }}>
          <Box
            display="flex"
            flexDirection="row"
            sx={{ width: "100%", height: "100%", backgroundColor: "white" }}
          >
            <StyledDataGrid
              sx={{ "&.MuiDataGrid-root .MuiDataGrid-cell": { py: "2px" } }}
              // getRowHeight={() => 'auto'}
              disableRowSelectionOnClick
              rows={rows}
              loading={loading}
              columns={columns}
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
                columns: {
                  columnVisibilityModel: {
                    association_name: false,
                  },
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    utf8WithBom: true,
                  },
                },
              }}
              pageSizeOptions={[15, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

import React from "react";
import { Product } from "@interfaces/Transactions";
import { OptionsMap } from "@utils/transactions/interfaces";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {
  extractOptionName,
  formatAmount,
  generateKeyWithPrice,
  newDate,
} from "@utils/helpers";
import { Box, Typography } from "@mui/material";
import { LicenceType } from "@utils/transactions/enums";
import { differenceInDays } from "date-fns";

const generateNameColumn = (days: number) => {
  return days >= 365 ? LicenceType.YEARS : LicenceType.DAYS;
};

const generateNumber = (days: number) => {
  return days >= 365 ? Math.round(days / 365) : days;
};

const extractProductsWithLicense = (
  products: Product[],
  optionsMap: Map<string, OptionsMap>,
) =>
  products.forEach((product: Product) => {
    const option = extractOptionName(product.licenseOption);
    const licenseKey = generateKeyWithPrice(option, product.price);
    const start = newDate(product.startDate);
    const end = newDate(product.endDate);
    const daysDifference = differenceInDays(end, start);
    const discount = product.discounts?.length ? product.discounts[0].name : "";

    if (!optionsMap.has(licenseKey)) {
      optionsMap.set(licenseKey, {
        name: option,
        value: product.price,
        days: daysDifference + 1,
        discount: discount,
      });
    }
  });

const sortLicenses = (licenses: OptionsMap[]) => {
  return licenses.sort((a, b) => {
    const aType = a.days >= 365 ? "annual" : "daily";
    const bType = b.days >= 365 ? "annual" : "daily";
    if (aType !== bType) {
      return aType === "annual" ? -1 : 1;
    }
    if (a.days !== b.days) {
      return a.days - b.days;
    }

    return a.value - b.value;
  });
};

export const generateColumns = (products: Product[]) => {
  const optionsMap: Map<string, OptionsMap> = new Map();
  extractProductsWithLicense(products, optionsMap);

  const sortedLicenses = sortLicenses(Array.from(optionsMap.values()));

  return sortedLicenses.map(
    (license: OptionsMap): GridColDef => ({
      field: generateKeyWithPrice(license.name, license.value),
      headerName: `${generateNumber(license.days)} ${generateNameColumn(license.days)} - ${license.name} (${formatAmount(license.value)} zł)`,
      width: (license.name.length + 10) * 9,
      minWidth: 220,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.optionsCount?.[
          generateKeyWithPrice(license.name, license.value)
        ],
      renderHeader: () => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0 auto",
              lineHeight: "1.1",
            }}
          >
            {license.discount && (
              <Typography variant="caption">
                Ulga: {license.discount}
              </Typography>
            )}
            <Typography variant="subtitle2">
              {generateNumber(license.days)} {generateNameColumn(license.days)}
              {" - "}
              {license.name}
            </Typography>
            <Typography variant="caption">
              ({formatAmount(license.value)}zł)
            </Typography>
          </Box>
        );
      },
    }),
  );
};

import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";

type AssociationClub = {
  association: string;
  name: string;
  club_id: string;
};

export const getAssociationClub = async (id: string | null) => {
  if (!id) return null;

  const docRef = doc(db, "association_clubs", id);
  const docSnap = await getDoc(docRef);

  return docSnap.exists() ? (docSnap.data() as AssociationClub) : null;
};

import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { format } from "date-fns";
import { getFishingMethodName } from "@utils/getFishingMethod";

export const UsersColumns: GridColDef[] = [
  {
    field: "created_time",
    headerName: "Data utworzenia",
    width: 140,
    valueGetter: (params: GridValueGetterParams) => {
      return !params.value?.seconds
        ? "-"
        : format(new Date(params.value?.seconds * 1000), "dd/MM/yyyy HH:mm");
    },
  },
  { field: "display_name", headerName: "Użytkownik", width: 180 },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  { field: "association_name", headerName: "Okręg PZW", width: 250 },
  { field: "association_club_name", headerName: "Koło PZW", width: 200 },
  { field: "fishing_card", headerName: "Karta wędkarska", width: 130 },
  {
    field: "association_card",
    headerName: "Legitymacja członkowska",
    width: 120,
  },
  {
    field: "isForeigner",
    headerName: "Czy obcokrajowiec",
    width: 130,
    renderCell: (params: GridRenderCellParams) => (
      <Chip
        sx={{ "& .MuiChip-label": { lineHeight: 1 } }}
        label={params.row.isForeigner ? "Tak" : "Nie"}
        variant="outlined"
        size="small"
        color={params.row.isForeigner === true ? "success" : "error"}
      />
    ),
  },
  {
    field: "favorite_fishing_method",
    headerName: "Ulubiona metoda połowu",
    width: 130,
    renderCell: (params: GridRenderCellParams) =>
      getFishingMethodName(params.row.favorite_fishing_method),
  },
  {
    field: "fishings",
    headerName: "Ilość połowów (1 rok)",
    width: 100,
  },
];

import { Fishing } from "@interfaces/Fishing";
import { startOfDay, endOfDay, format } from "date-fns";
import { DataRange } from "@interfaces/Date";

interface ChartData {
  xAxisData: string[];
  seriesData: number[];
}

export const transformDataBarChartNumberCatches = (
  data: Fishing[],
  selectedRange: DataRange,
): ChartData => {
  const adjustedStartDate = startOfDay(selectedRange.startDate);
  const adjustedEndDate = endOfDay(selectedRange.endDate);

  const filteredData = data.filter((fishing) => {
    const fishingDate = new Date(fishing.start_date.seconds * 1000);
    return fishingDate >= adjustedStartDate && fishingDate <= adjustedEndDate;
  });

  const countsByDay: Record<string, number> = {};
  const dateToTimestamp: Record<string, number> = {};

  filteredData.forEach((item) => {
    const date = new Date(item.start_date.seconds * 1000);
    const dateString = format(date, "dd-MM-yyyy");
    countsByDay[dateString] = (countsByDay[dateString] || 0) + 1;
    dateToTimestamp[dateString] = date.getTime();
  });

  const sortedDates = Object.keys(countsByDay).sort(
    (a, b) => dateToTimestamp[a] - dateToTimestamp[b],
  );

  const xAxisData = sortedDates;
  const seriesData = sortedDates.map((date) => countsByDay[date]);

  return {
    xAxisData,
    seriesData,
  };
};

import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { useResolvedPath, useMatch } from "react-router-dom";

type ListItemProps = {
  icon?: React.ReactNode;
  onClick: React.MouseEventHandler;
  label: string;
  to: string;
};

const ListItem: React.FC<ListItemProps> = ({ icon, onClick, label, to }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <ListItemButton
      sx={{ px: 3, minHeight: 32 }}
      onClick={onClick}
      selected={!!match}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primaryTypographyProps={{ fontSize: 14, fontWeight: "medium" }}
        primary={label}
      />
    </ListItemButton>
  );
};

export default ListItem;

import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid, GridToolbar, plPL } from "@mui/x-data-grid";
import { UsersColumns } from "src/consts/users-columns";
import { useUserStore } from "@store/useUserStore/useUserStore";
import { useUsersWithFishingStore } from "@store/useUsersWithFishingStore/useUsersWithFishingStore";
import { DataRange } from "@interfaces/Date";
import { DEFAULT_START_END_DATE } from "../consts/date";
import DatePickerDialog from "@components/DatePickerDialog";
import useFishingDataWithUser from "@hooks/useFishingDataWithUser";

export const UsersPage: React.FC = () => {
  const { loading, user, users, getUsers } = useUserStore();
  const { usersWithFishing, getUsersWithFishing } = useUsersWithFishingStore();
  const { fishingsWithUser } = useFishingDataWithUser();

  const [selectedRange, setSelectedRange] = useState<DataRange>(
    DEFAULT_START_END_DATE,
  );

  const handleConfirmDialogDateRange = (range: DataRange) => {
    setSelectedRange(range);
  };

  useEffect(() => {
    if (user?.uid) {
      getUsers(user, selectedRange);
    }
  }, [user, selectedRange]);

  useEffect(() => {
    if (user?.uid) {
      getUsersWithFishing(users, fishingsWithUser);
    }
  }, [users, fishingsWithUser, loading]);

  return (
    <Layout>
      <Grid container spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <DatePickerDialog onConfirm={handleConfirmDialogDateRange} />
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ width: "100%", height: 760, backgroundColor: "white" }}
          >
            <DataGrid
              disableRowSelectionOnClick
              rows={usersWithFishing}
              loading={loading}
              columns={UsersColumns}
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    utf8WithBom: true,
                  },
                },
              }}
              pageSizeOptions={[15, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

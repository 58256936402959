import { Collection } from "@utils/api/enums";
import {
  getCollectionCount,
  updateAssociationsByProduct,
} from "@utils/api/collections";

import { create } from "zustand";

import { DEFAULT_COLLECTIONS_STATE } from "./consts";
import { CollectionsState } from "./interfaces";

export const useCollectionsStore = create<CollectionsState>((set) => ({
  ...DEFAULT_COLLECTIONS_STATE,

  reset: () => set(DEFAULT_COLLECTIONS_STATE),
  getCollections: async () => {
    set({
      loading: true,
    });

    const getCounts = Object.entries(Collection).map(async (entry) => {
      const [key, value] = entry;
      const count = await getCollectionCount(value);

      return {
        id: key,
        name: value,
        counter: count,
      };
    });

    const collectionsDetails = await Promise.all(getCounts);
    set({
      loading: false,
      collections: collectionsDetails,
    });
  },
  updateCollectionsAssociationByProduct: async (name: string) => {
    await updateAssociationsByProduct(name);
  },
}));

import React from "react";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";

interface CardProps {
  title?: string;
  children: React.ReactNode;
  minHeight?: number | string;
}
export const CardComponent: React.FC<CardProps> = ({
  children,
  title,
  minHeight = 360,
}) => {
  return (
    <Card sx={{ minHeight }}>
      {title && (
        <CardHeader title={<Typography variant="h6">{title}</Typography>} />
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

import React, { useEffect, useState } from "react";
import Layout from "@components/Layout";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import DatePickerDialog from "@components/DatePickerDialog";
import { DataRange } from "@interfaces/FishingClubs";
import {
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueGetterParams,
  plPL,
} from "@mui/x-data-grid";
import { DEFAULT_START_END_DATE } from "../consts/date";
import { useTransactionsStore } from "@store/useTransactionsStore";
import StyledDataGrid from "@components/StyledDataGrid";
import { validDate, statusName } from "@utils/helpers";
import { useUserStore } from "@store/useUserStore/useUserStore";

export const TransactionsPage: React.FC = () => {
  const { user } = useUserStore();
  const { transactionsGroup, loading, getTransactions } =
    useTransactionsStore();

  const [selectedRange, setSelectedRange] = useState<DataRange>(
    DEFAULT_START_END_DATE,
  );

  const handleConfirmDialogDateRange = (range: DataRange) => {
    setSelectedRange(range);
  };

  useEffect(() => {
    getTransactions(user, selectedRange);
  }, [user, selectedRange]);

  const columns: GridColDef[] = [
    { field: "transactionId", headerName: "Transaction ID", width: 300 },
    { field: "orderId", headerName: "Order ID", width: 120 },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      renderCell: (params) => statusName(params.row.status),
    },
    {
      field: "transactionDate",
      headerName: "Data zakupu",
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        validDate(params.value, "yyyy-MM-dd HH:mm"),
      renderCell: (params) =>
        validDate(params.row.transactionDate, "dd/MM/yyyy HH:mm"),
    },
    { field: "associationName", headerName: "Okręg", flex: 1, minWidth: 300 },
    { field: "email", headerName: "Email", width: 120 },
    { field: "userName", headerName: "Imię i Nazwisko", width: 150 },
    { field: "fishingCard", headerName: "Karta wędkarska", width: 130 },
    {
      field: "isForMe",
      headerName: "Czy dla siebie",
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          sx={{ "& .MuiChip-label": { lineHeight: 1 } }}
          label={params.row.isForMe}
          variant="outlined"
          size="small"
          color={params.row.isForMe === "Tak" ? "success" : "error"}
        />
      ),
    },
    {
      field: "isForeigner",
      headerName: "Czy obcokrajowiec",
      width: 140,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          sx={{ "& .MuiChip-label": { lineHeight: 1 } }}
          label={params.row.isForeigner}
          variant="outlined"
          size="small"
          color={params.row.isForeigner === "Tak" ? "success" : "error"}
        />
      ),
    },
    {
      field: "isMemberParticipant",
      headerName: "Czy członek uczestnik",
      width: 160,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          sx={{ "& .MuiChip-label": { lineHeight: 1 } }}
          label={params.row.isMemberParticipant}
          variant="outlined"
          size="small"
          color={params.row.isMemberParticipant === "Tak" ? "success" : "error"}
        />
      ),
    },
    {
      field: "associationFee",
      headerName: "Czy składka członkowska",
      width: 180,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          sx={{ "& .MuiChip-label": { lineHeight: 1 } }}
          label={params.row.associationFee}
          variant="outlined"
          size="small"
          color={params.row.associationFee === "Tak" ? "success" : "error"}
        />
      ),
    },
    {
      field: "associationDiscount",
      headerName: "Składka - zniżka",
      width: 210,
    },
    {
      field: "associationEntryFee",
      headerName: "Składka - wpisowe",
      width: 140,
      renderCell: (params: GridRenderCellParams) =>
        params.row.associationEntryFee && (
          <Chip
            sx={{ "& .MuiChip-label": { lineHeight: 1 } }}
            label={params.row.associationEntryFee}
            variant="outlined"
            size="small"
            color={
              params.row.associationEntryFee === "Tak" ? "success" : "error"
            }
          />
        ),
    },
    { field: "associationCard", headerName: "Numer legitymacji", width: 140 },
    { field: "associationClub", headerName: "Koło PZW", width: 160 },
    {
      field: "associationClubNumber",
      headerName: "Numer koło PZW",
      width: 160,
    },
    {
      field: "associationPrice",
      headerName: "Składka - opłata",
      width: 160,
      renderCell: (params) => {
        const currency = params.row.currency;
        return params.row.associationPrice
          ? `${params.row.associationPrice.toFixed(2)} ${currency}`
          : "";
      },
    },
    { field: "licenseOption", headerName: "Zezwolenie - rodzaj", width: 290 },
    {
      field: "licenseStartDate",
      headerName: "Zezwolenie - start",
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        validDate(params.value, "yyyy-MM-dd HH:mm"),
      renderCell: (params) =>
        validDate(params.row.licenseStartDate, "dd/MM/yyyy HH:mm"),
    },
    {
      field: "licenseEndDate",
      headerName: "Zezwolenie - koniec",
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        validDate(params.value, "yyyy-MM-dd HH:mm"),
      renderCell: (params) =>
        validDate(params.row.licenseEndDate, "dd/MM/yyyy HH:mm"),
    },
    { field: "licenseDiscount", headerName: "Zezwolenie - zniżka", width: 160 },
    {
      field: "licensePrice",
      headerName: "Zezwolenie - opłata",
      width: 160,
      renderCell: (params) => {
        const currency = params.row.currency;
        if (params.row.licensePrice) {
          return `${params.row.licensePrice.toFixed(2)} ${currency}`;
        }
        return "";
      },
    },
    {
      field: "amount",
      headerAlign: "right",
      align: "right",
      headerName: "Opłata całkowita",
      width: 160,
      renderCell: (params) => {
        const currency = params.row.currency;
        return (
          <strong>
            {params.row.amount.toFixed(2)} {currency}
          </strong>
        );
      },
    },
  ];

  return (
    <Layout>
      <Grid container spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <DatePickerDialog onConfirm={handleConfirmDialogDateRange} />
        </Grid>
        <Grid item xs={12} sx={{ height: "calc(100% - 70px)" }}>
          <Box
            display="flex"
            flexDirection="row"
            sx={{ width: "100%", height: "100%", backgroundColor: "white" }}
          >
            <StyledDataGrid
              disableRowSelectionOnClick
              rows={transactionsGroup}
              loading={loading}
              columns={columns}
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15, page: 0 } },
                columns: {
                  columnVisibilityModel: {
                    associationName: false,
                  },
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    utf8WithBom: true,
                  },
                },
              }}
              pageSizeOptions={[15, 25, 100]}
              slots={{ toolbar: GridToolbar }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

import { User } from "@interfaces/User";

import { ROLES } from "@store/useUserStore/enums";
import { UserState } from "@store/useUserStore/interfaces";

import { getUsersData } from "@utils/api/users/getUsersData";

import { create } from "zustand";

import { DEFAULT_USERS_STATE } from "./consts";
import { DataRange } from "@interfaces/FishingClubs";

export const useUserStore = create<UserState>((set, get) => ({
  ...DEFAULT_USERS_STATE,

  reset: () => set(DEFAULT_USERS_STATE),
  setLoading: (loading) => set(() => ({ loading })),
  setUser: (data: Partial<User>) =>
    set((state: UserState) => ({ user: { ...state.user, ...data } })),

  hasRole: (roles: ROLES[]) =>
    get().user.roles.some((role) => roles.includes(role as ROLES)),
  getUsers: async (user: User, selectedRange: DataRange) => {
    set({ loading: true });
    const rawUsers = await getUsersData(user, selectedRange);
    set({ users: rawUsers, loading: false });
  },
}));

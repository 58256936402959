import { Product } from "@interfaces/Transactions";
import { extractOptionName, generateKeyWithPrice } from "@utils/helpers";
import { generateColumns } from "@utils/transactions/generateColumns";
import { AggregatedData } from "@utils/transactions/interfaces";
import { ProductType } from "../../consts/transaction";

export const transformPeriodicPermits = (products: Product[]) => {
  const aggregatedData: AggregatedData = {
    id: "total",
    optionsCount: {},
    totalPrice: 0,
  };

  const filteredProducts = products.filter((product) => {
    return product && product.type === ProductType.LICENSE;
  });

  filteredProducts.forEach((product) => {
    const option = extractOptionName(product.licenseOption);
    const optionKey = generateKeyWithPrice(option, product.price);

    aggregatedData.optionsCount[optionKey] =
      (aggregatedData.optionsCount[optionKey] || 0) + 1;

    aggregatedData.totalPrice += product.price;
  });

  const columns = generateColumns(filteredProducts);
  const data = [aggregatedData];

  return { data, columns };
};

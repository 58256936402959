import { db } from "../../firebase-config";
import { DocumentReference, addDoc, collection, doc } from "firebase/firestore";
import { tryCatchFunc } from "./helpers";
import { Collection, ResponseStatus } from "./enums";
import { Fishery } from "../../interfaces/Fishery";

interface ResponseAddFisheryData {
  status: ResponseStatus;
  doc: DocumentReference | null;
}

export const addFisheryData = async (
  fishery: Fishery,
  finnalyFunc: () => void = () => undefined,
): Promise<ResponseAddFisheryData> => {
  const addFishery = async (
    fishery: Fishery,
  ): Promise<ResponseAddFisheryData> => {
    const col = collection(db, "fisheries");
    // TODO ADD REAL ASSOCIATIONID
    const association_id = doc(db, "/associations/GMUe0Hd56WJ7U0HQ3qpa");
    const data = {
      ...fishery,
      location: fishery.location.map((el) => Object.assign({}, el)),
      association_id,
    };
    // return ResponseStatus.SUCCESS;
    return await addDoc(col, data).then((doc) => ({
      status: ResponseStatus.SUCCESS,
      doc,
    }));
  };
  const catchFunc = (error: unknown): ResponseAddFisheryData => {
    console.error(
      `Failed to add new fishery to ${Collection.FISHERIES} collection. Error:`,
      error,
    );
    return { status: ResponseStatus.FAIL, doc: null };
  };

  return tryCatchFunc(() => addFishery(fishery), catchFunc, finnalyFunc);
};

import { Transaction } from "@interfaces/Transactions";
import { User } from "@interfaces/User";
import { DataRange } from "@interfaces/FishingClubs";
import { ROLES } from "@store/useUserStore/enums";
import {
  collection,
  doc,
  getDocs,
  Query,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { startOfDay, endOfDay, subMonths } from "date-fns";
import { Collection } from "../enums";
import { tryCatchFunc } from "../helpers";
import { db } from "../../../firebase-config";

export const getTransactionData = async (
  user: User,
  selectedRange?: DataRange | undefined,
  status?: string[],
  finallyFunc: () => void = () => undefined,
): Promise<Transaction[]> => {
  if (!user.uid) {
    return [];
  }

  const getTransaction = async (): Promise<Transaction[]> => {
    const query = transactionQuery(user, selectedRange, status);
    const querySnapshot = await getDocs(query);
    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),
    })) as Transaction[];
  };
  const catchFunc = (error: unknown): [] => {
    console.error("Failed to fetch transactions data:", error);
    return [];
  };

  return tryCatchFunc(getTransaction, catchFunc, finallyFunc);
};

const transactionQuery = (
  user: User,
  selectedRange?: DataRange,
  status?: string[],
): Query => {
  let q = query(collection(db, Collection.TRANSACTIONS));

  // data access
  if (user.roles.includes(ROLES.SUPERADMIN)) {
    // all data
    q = query(q);
  } else if (user.roles.includes(ROLES.ADMIN)) {
    // association data only
    const associationDocRef = doc(
      db,
      Collection.ASSOCATIONS,
      user.association_id?.id ?? "",
    );
    q = query(q, where("association", "==", associationDocRef));
  } else {
    // user data only
    const userDocRef = doc(db, Collection.USERS, user.uid);
    q = query(q, where("user", "==", userDocRef));
  }

  // data range
  const startDate = selectedRange
    ? selectedRange.startDate
    : subMonths(new Date(), 12);
  const endDate = selectedRange ? selectedRange.endDate : new Date();
  q = query(
    q,
    where("createdAt", ">=", startOfDay(startDate)),
    where("createdAt", "<=", endOfDay(endDate)),
  );

  // status
  if (status) {
    q = query(q, where("status", "in", status));
  }

  // sorting
  q = query(q, orderBy("createdAt", "desc"));

  return q;
};
